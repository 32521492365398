import React from "react";
import { useForm } from "react-hook-form";
import { object, string, bool } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from "./Input";
import { H3 } from "./H3";
import { Button } from "./Button";
import { CheckBox } from "./CheckBox";
import { ErrorText } from "./ErrorText";
import { DateOfBirth } from "./DateOfBirth";

import { isAfter, isValid, parse, sub } from "date-fns";
import { H2 } from "./H2";
import { P } from "./P";

const schema = object()
  .shape({
    FirstName: string().required(),
    Surname: string().required(),
    DateOfBirth: string()
      .required()
      .test("DateOfBirth", "Invalid Date", (value) => {
        const formated = parse(value, "dd/MM/yyyy", new Date());
        return (
          isValid(formated) &&
          !isAfter(formated, sub(new Date(), { years: 16 }))
        );
      }),
    Company: string().required(),
    EmailAddress: string().email().required(),
    PhoneNumber: string()
      .matches(
        /^(?:\+44\s?|0)[1-9]{1}[0-9]{9}$/,
        "Phone number must be valid UK format",
      )
      .required(),
    NINumber: string()
      .nullable()
      .transform((v, o) => (o === "" ? null : v))
      .matches(
        /^(?!BG|GB|NK|KN|TN|NT|ZZ)(?:\w{2})\s?(?:\d{2})\s?(?:\d{2})\s?(?:\d{2})\s?(\w{1})$/,
        "National Insurance number must be valid UK format",
      ),
    AddressLine1: string().required(),
    AddressLine2: string(),
    TownCity: string().required(),
    County: string().required(),
    Postcode: string()
      .matches(
        /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/,
        "Postcode must be a valid UK format",
      )
      .required(),
    AgreedTerms: bool().oneOf([true]).required(),
  })
  .required();

const resolver = yupResolver(schema);

export function FormVortexRegistration({ onSubmit, defaultValues }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm({ resolver, mode: "onBlur", defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
      <H2>Course Registration</H2>
      <P>
        Please enter your details below to register for the course.{" "}
        <strong>
          IMPORTANT: Candidates are responsible for entering their details
          correctly.
        </strong>
      </P>
      <H3>Personal Details</H3>
      <div className="flex w-full flex-col space-y-6 sm:flex-row sm:gap-x-6 sm:space-y-0">
        <Input
          type="text"
          required
          {...register("FirstName", { required: true })}
          label="First Name(s)"
          errorText={errors.FirstName?.message}
        />
        <Input
          type="text"
          required
          {...register("Surname", { required: true })}
          label="Surname"
          errorText={errors.Surname?.message}
        />
        <DateOfBirth
          control={control}
          name="DateOfBirth"
          errorText={errors.DateOfBirth}
        />
      </div>
      <H3>Work Information</H3>
      <div className="flex w-full flex-col space-y-6 sm:flex-row sm:gap-x-6 sm:space-y-0">
        <Input
          type="text"
          required
          {...register("Company", { required: true })}
          label="Company"
          errorText={errors.Company?.message}
        />
        <Input
          type="text"
          {...register("NINumber", { required: false })}
          label="National Insurance Number"
          transform="uppercase"
          errorText={errors.NINumber?.message}
        />
      </div>
      <H3>Contact Information</H3>
      <div className="flex w-full flex-col space-y-6 sm:flex-row sm:gap-x-6 sm:space-y-0">
        <Input
          type="email"
          required
          {...register("EmailAddress", { required: true })}
          label="Email Address"
          errorText={errors.EmailAddress?.message}
        />
        <Input
          type="tel"
          required
          {...register("PhoneNumber", { required: true })}
          label="Phone Number"
          inputtype="number"
          errorText={errors.PhoneNumber?.message}
        />
      </div>
      <H3>Your Address</H3>
      <div className="flex w-full flex-col space-y-6 sm:flex-row sm:gap-x-6 sm:space-y-0">
        <Input
          type="text"
          required
          {...register("AddressLine1", { required: true })}
          label="Address Line 1"
          errorText={errors.AddressLine1?.message}
        />
        <Input
          type="text"
          {...register("AddressLine2", { required: false })}
          label="Address Line 2"
        />
        <Input
          type="text"
          required
          {...register("TownCity", { required: true })}
          label="Town/City"
          errorText={errors.TownCity?.message}
        />
      </div>
      <div className="flex w-full flex-col space-y-6 sm:flex-row sm:gap-x-6 sm:space-y-0">
        <Input
          type="text"
          required
          {...register("County", { required: true })}
          label="County"
          errorText={errors.County?.message}
        />
        <Input
          type="text"
          required
          {...register("Postcode", { required: true })}
          label="Postcode"
          transform="uppercase"
          errorText={errors.Postcode?.message}
        />
      </div>
      <div>
        {!!errors.AgreedTerms && (
          <ErrorText>You must accept the Terms.</ErrorText>
        )}
        <CheckBox.FormField
          formKey="AgreedTerms"
          control={control}
          id="AgreedTerms"
          rules={{ required: true }}
          label="I agree to the Vortal Terms and Conditions and understand that I will be responsible for the cost of fixing any incorrect information."
        />
      </div>
      <div className="mt-6 text-right">
        <Button type="submit" variant="cta" disabled={!isValid}>
          Next
        </Button>
      </div>
    </form>
  );
}
