import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StarFilledIcon } from "@radix-ui/react-icons";

import { TrainerDelegateList } from "./TrainerDelegateList";
import { H3 } from "./H3";
import { P } from "./P";
import { QRReader } from "./QRReader";
import {
  addDelegateData,
  totalDelegates,
  nDelegates,
  currentStage,
  currentStageData,
} from "../slices/trainer";
import { PageLayout } from "./PageLayout";
import { CameraList } from "./CameraList";

export function PageTrainerFeedback() {
  const dispatch = useDispatch();
  const [readerIsOpen, setReaderIsOpen] = useState(false);
  const total = useSelector(totalDelegates);
  const n = useSelector(nDelegates);
  const stage = useSelector(currentStage);
  const delegates = useSelector((state) => state.trainer.delegates);
  const current = useSelector(currentStageData);

  const completed = Object.keys(current);

  const validate = useCallback((data) => {
    return (
      typeof data?.userId === "string" &&
      Array.isArray(data?.data) &&
      typeof data.data[0] === "number"
    );
  }, []);

  return stage?.Type === "FEEDBACK" ? (
    <PageLayout>
      <div className="-mt-4 flex flex-col space-y-4">
        <H3>Feedback</H3>
        <P>
          Each of the delegates will be asked for feedback. Once they have
          completed feedback, scan each of their QR codes.
        </P>
        <TrainerDelegateList
          content={Content}
          onSelect={() => setReaderIsOpen(true)}
          buttonText="Scan Feedback"
          isComplete={(data) => !!data}
        />
      </div>
      {readerIsOpen && n < total && stage?.Type === "FEEDBACK" && (
        <QRReader
          close={() => setReaderIsOpen(false)}
          onComplete={(data) => dispatch(addDelegateData(data))}
          validateQR={validate}
        >
          <CameraList items={delegates} completeItems={completed} />
        </QRReader>
      )}
    </PageLayout>
  ) : (
    <PageLayout />
  );
}

function Content({ userId, ...answers }) {
  const { starRating, comments } = useMemo(() => {
    const total = Object.keys(answers).reduce(
      (obj, a) => {
        const v = answers[a];
        if (typeof v !== "number") return { ...obj, comments: v };
        return {
          ...obj,
          n: obj.n + 1,
          total: obj.total + v,
        };
      },
      { n: 0, total: 0, comments: "" },
    );

    return {
      comments: total.comments,
      starRating: 2.5 + (total.total / total.n) * 2.5,
    };
  }, [answers]);

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      <div className="flex items-center gap-1 font-bold">
        {round(starRating)}{" "}
        <StarFilledIcon className="h-4 w-4 text-yellow-500" />
      </div>
      <p>{comments || "No comments"}</p>
    </div>
  );
}

function round(n) {
  return Math.round(n * 100) / 100;
}
