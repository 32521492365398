import React from "react";
import { MaskFace } from "./MaskFace";
import { MaskPaper } from "./MaskPaper";
import { MaskQR } from "./MaskQR";

const maskByType = {
  face: MaskFace,
  paper: MaskPaper,
  qr: MaskQR,
};

export function Mask({ type, width, height, isActive }) {
  const Shape = maskByType[type];

  if (!width || !height || !type || !Shape) return null;

  if (!Shape) return null;

  return (
    <svg
      className="absolute left-0 top-0 z-40 h-full w-full"
      viewBox={`0 0 ${width} ${height}`}
      width="100%"
      height="100%"
    >
      <mask id="mask" x="0" y="0" width="100%" height="100%">
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="white"
          fillOpacity="0.5"
        />
        {<Shape width={width} height={height} isMask={true} />}
      </mask>
      <Shape
        width={width}
        height={height}
        style={
          isActive
            ? {
                stroke: "#10b981",
                transition: "stroke 100ms ease-in-out",
              }
            : {
                stroke: "rgba(0,0,0,0.7)",
                transition: "stroke 1s ease-in-out",
              }
        }
        fill="none"
        strokeWidth="6"
      />
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        mask="url(#mask)"
        fill="black"
      />
    </svg>
  );
}
