import { toUserId } from "./toUserId";

export function delegate(firstName) {
  return {
    FirstName: firstName,
    Surname: "Smith",
    DateOfBirth: "01/01/1990",
    Company: "Co",
    EmailAddress: "email@address.com",
    PhoneNumber: "07900123456",
    NINumber: "JKK 21 20 01 D",
    AddressLine1: "Address Line 1",
    AddressLine2: "Address Line 2",
    TownCity: "Manchester",
    County: "Greater Manchester",
    Postcode: "M1 1AD",
    AgreedTerms: true,
    TermsAgreedAt: new Date().getTime(),
  };
}

const fnames = [
  "John",
  "Jane",
  "Joe",
  "Steve",
  "Jenny",
  "Walter",
  "Kate",
  "Leah",
];

export function VORTEX_REGISTRATION(nDelegates) {
  return new Array(nDelegates).fill(1).reduce((obj, v, i) => {
    const d = delegate(fnames[i]);
    const userId = toUserId(d);

    return {
      ...obj,
      [userId]: d,
    };
  }, {});
}

export function CANDIDATE_PHOTOS(nDelegates) {
  return new Array(nDelegates).fill(1).reduce((obj, v, i) => {
    const d = delegate(fnames[i]);
    const userId = toUserId(d);

    return {
      ...obj,
      [userId]: {
        image: "Uqka0q7c7I5ZecvVJGiC8.jpeg",
      },
    };
  }, {});
}

export function RESULTS(nDelegates) {
  return new Array(nDelegates).fill(1).reduce((obj, v, i) => {
    const d = delegate(fnames[i]);
    const userId = toUserId(d);

    return {
      ...obj,
      [userId]: {
        theory: true,
        practical: true,
      },
    };
  }, {});
}
