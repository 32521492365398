import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckIcon, Cross1Icon, Pencil1Icon } from "@radix-ui/react-icons";

import { TrainerDelegateList } from "./TrainerDelegateList";
import { H3 } from "./H3";
import { P } from "./P";
import { Link } from "./Link";
import {
  addDelegateData,
  currentStage,
  totalDelegates,
  nDelegates,
  subRoute,
  setSubRoute,
  edit,
} from "../slices/trainer";
import { FormPractical } from "./FormPractical";
import { PageLayout } from "./PageLayout";

export function PageTrainerPractical() {
  const dispatch = useDispatch();
  const currentUserId = useSelector(subRoute);
  const stage = useSelector(currentStage);
  const total = useSelector(totalDelegates);
  const n = useSelector(nDelegates);
  const selectUser = (userId) => dispatch(setSubRoute(userId));
  const questions = stage?.Details?.CourseQuestions || [];

  const answerableQuestions = useMemo(() => {
    return questions.filter(
      (q) => q.Type === "CheckBox" || q.Type === "MultipleChoice",
    );
  }, [questions]);

  return (
    <PageLayout>
      <div className="space-y-4">
        <H3>Practical</H3>
        <P>Complete the practical with each of the candidates.</P>
        <P>
          Once complete, mark each candidate by hitting the &quot;Mark
          Practical&quot; button next to their name.
        </P>
        <TrainerDelegateList
          content={Content}
          onSelect={selectUser}
          colorFn={({ pass }) => (pass ? "bg-emerald-50" : "bg-red-50")}
          buttonText="Mark Practical"
          isComplete={(data) => !!data}
        />
      </div>
      {!!currentUserId && n < total && (
        <div className="absolute left-0 top-0 h-full w-full overflow-y-auto bg-white pb-16 pt-4">
          <div
            className="absolute right-0 top-0 cursor-pointer p-4"
            onClick={() => selectUser(null)}
          >
            <Cross1Icon className="h-8 w-8 text-black" />
          </div>
          <div className="p-4">
            <FormPractical
              onSubmit={(data) => {
                dispatch(addDelegateData({ userId: currentUserId, data }));
                selectUser(null);
              }}
              answerableQuestions={answerableQuestions}
              questions={stage.Details.CourseQuestions}
            />
          </div>
        </div>
      )}
    </PageLayout>
  );
}

function Content({ pass, totalMarks, userId }) {
  const dispatch = useDispatch();
  const stageInfo = useSelector(currentStage);
  const onEditClick = () => dispatch(edit({ userId }));
  const { CourseQuestions } = stageInfo.Details;
  const filteredQuestions = CourseQuestions.filter(
    (q) => q.Type === "CheckBox" || q.Type === "MultipleChoice",
  );

  const Icon = pass ? CheckIcon : Cross1Icon;
  const color = pass ? "green" : "red";
  const className = `flex items-center gap-1 font-bold text-${color}-700`;

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      <span className="flex justify-start gap-2">
        <span className={className}>
          <Icon className="h-6 w-6" />
          {pass ? "PASSED" : "FAILED"}
        </span>
        {totalMarks} / {filteredQuestions.length}
      </span>
      <Link onClick={onEditClick} className="flex flex-row items-center gap-2">
        <Pencil1Icon className="h-6 w-6" /> Edit
      </Link>
    </div>
  );
}
