import React, { useState, useMemo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowLeftIcon,
  PersonIcon,
  InfoCircledIcon,
} from "@radix-ui/react-icons";

import { toggleShowLatestStage } from "../slices/delegate";
import { back as trainerBack } from "../slices/trainer";
import { setUserType } from "../slices/user";
import { Link } from "./Link";
import { Menu } from "./Menu";

export function Header({ isSplashPage }) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.type);
  const d = useSelector((state) => state.delegate);
  const t = useSelector((state) => state.trainer);
  const [showVersion, setShowVersion] = useState(false);

  const ref = useRef(null);

  const back = useMemo(() => {
    if (userType === "DELEGATE") {
      if (d.stages === null) return () => dispatch(setUserType(null));
      if (!d.showLatestStage && d.stageData?.length) {
        return () => dispatch(toggleShowLatestStage());
      }

      return null;
    } else if (userType === "TRAINER") {
      if (!t.course) return () => dispatch(setUserType(null));
      return () => dispatch(trainerBack());
    }

    return null;
  }, [dispatch, userType, d, t, setUserType]);

  useEffect(() => {
    if (!showVersion) return;
    const timeout = setTimeout(() => {
      setShowVersion(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [showVersion, setShowVersion]);

  return (
    <header className="block" ref={ref}>
      <div className="flex basis-12 flex-row items-center justify-between text-white">
        {isSplashPage ? (
          <>
            <div className="flex items-center gap-2">
              <button
                className="rounded-lg p-2 underline"
                onClick={() => setShowVersion(true)}
              >
                <InfoCircledIcon className="h-6 w-6" />
              </button>
              <span
                className="transition-opacity"
                style={{ opacity: showVersion ? 1 : 0 }}
              >
                v1.2.11
              </span>
            </div>
            <button
              className="rounded-lg p-2 underline"
              onClick={() => dispatch(setUserType("TRAINER"))}
            >
              <PersonIcon className="h-6 w-6" />
            </button>
          </>
        ) : (
          <>
            {!!back && (
              <Link onClick={back} className="p-4">
                <ArrowLeftIcon className="h-6 w-6 text-white" />
              </Link>
            )}
            <div className="px-6" />
            <Menu />
          </>
        )}
      </div>
    </header>
  );
}
