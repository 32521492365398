import React from "react";
import { FormLogin } from "./FormLogin";

export function PageTrainerLogin() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <FormLogin />
    </div>
  );
}
