import React, { forwardRef } from "react";

const colorByVariant = {
  regular: "bg-blue-600 hover:bg-blue-700 text-white",
  cta: "bg-emerald-800 text-white animate-bounce",
  secondary: "bg-slate-100 text-slate-800",
  action: "bg-white border-2 border-dashed border-slate-300 text-slate-800",
  inconspic: "bg-white text-slate-800 text-md underline",
  rinconspic: "bg-black text-slate-50 text-md underline",
};

function ButtonComponent(
  { variant = "regular", className, ...buttonProps },
  ref,
) {
  const ButtonClassnames = `disabled:opacity-50 disabled:animate-none inline-flex flex-row items-center gap-2 rounded-md px-5 py-2.5 text-center text-sm font-medium focus:outline-none focus:ring-4`;

  return (
    <button
      className={`${ButtonClassnames} ${colorByVariant[variant]} ${className}`}
      ref={ref}
      {...buttonProps}
    />
  );
}

export const Button = forwardRef(ButtonComponent);
