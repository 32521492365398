import React from "react";
import { useSelector } from "react-redux";
import { useSpring, animated } from "react-spring";

import VortalLogo from "../Images/VortalLogo.png";

export function Logo() {
  const isLogoInHeader = useSelector((state) => !!state.user.type);

  const logoProps = useSpring({
    top: isLogoInHeader ? "0%" : "50%",
    marginTop: isLogoInHeader ? 6 : -80,
    width: isLogoInHeader ? 100 : 240,
  });

  return (
    <animated.img
      className="absolute left-1/2 h-auto max-w-[500px] -translate-x-1/2 opacity-90"
      style={{
        top: logoProps.top,
        width: logoProps.width,
        marginTop: logoProps.marginTop,
      }}
      src={VortalLogo}
      alt="Vortal Logo"
    />
  );
}
