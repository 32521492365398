import React from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

import { Button } from "./Button";

export function Confirm({
  onConfirm,
  onCancel,
  cancelText,
  confirmText,
  title,
  description,
}) {
  return (
    <AlertDialog.Root
      open={true}
      onOpenChange={(isOpen) => !isOpen && onCancel()}
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          className="fixed left-0 top-0 z-40 h-full w-full bg-black/70"
          onClick={onCancel}
        />
        <AlertDialog.Content className="fixed left-1/2 top-1/2 z-50 flex w-[90%] max-w-[350px] -translate-x-1/2 -translate-y-1/2 flex-col gap-4 rounded-md bg-white p-4">
          <AlertDialog.Title className="font-bold text-blue-900">
            {title}
          </AlertDialog.Title>
          <AlertDialog.Description className="border-y border-slate-100 py-4">
            {description}
          </AlertDialog.Description>
          <div className="flex justify-between">
            <AlertDialog.Cancel asChild>
              <Button variant="secondary">{cancelText || "Cancel"}</Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button onClick={onConfirm}>{confirmText || "Confirm"}</Button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
