import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { QRReader } from "./QRReader";
import { currentStage, login } from "../slices/delegate";
import { setUserType } from "../slices/user";

export function PageDelegateLogin() {
  const [isReady, setIsReady] = useState(false);
  const toRef = useRef();
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(setUserType(null));
  }, [dispatch, setUserType]);

  const onComplete = useCallback(
    (data) => {
      dispatch(login(data));
    },
    [login, dispatch],
  );

  const validate = useCallback((data) => {
    return Array.isArray(data) && data?.[0]?.Type === "VORTEX_REGISTRATION";
  }, []);

  const type = useSelector((state) => state.user.type);
  const current = useSelector(currentStage);

  useEffect(() => {
    if (type === "DELEGATE" && !current)
      toRef.current = setTimeout(() => setIsReady(true), 300);
    else {
      clearTimeout(toRef);
      setIsReady(false);
    }

    return () => clearTimeout(toRef);
  }, [type, current]);

  return (
    <div className="relative h-full w-full">
      {isReady && (
        <QRReader onComplete={onComplete} close={close} validateQR={validate}>
          <div className="absolute left-0 top-0 z-20 flex w-full flex-row justify-between bg-black/80 p-4 text-white">
            <div className="flex flex-row">
              Scan the QR Code on the trainer&apos;s iPad to sign in
            </div>
          </div>
        </QRReader>
      )}
    </div>
  );
}
