import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { H3 } from "./H3";
import { P } from "./P";
import { WebcamVideo } from "./WebcamVideo";
import { PaperworkList } from "./PaperworkList";
import {
  subRoute,
  currentStageData,
  currentStage,
  addDelegateData,
  setSubRoute,
  confirmStageCompletion,
} from "../slices/trainer";
import { idToName } from "../utils";
import { Button } from "./Button";
import { PageLayout } from "./PageLayout";

export function PagePaperwork() {
  const dispatch = useDispatch();
  const currentSubRoute = useSelector(subRoute);
  const currentData = useSelector(currentStageData);
  const delegates = useSelector((state) => state.trainer.delegates);
  const stage = useSelector(currentStage);

  const items = stage?.Details?.Items || [];

  const allRequired = useMemo(() => {
    const keysForEachDelegate = items.reduce((arr, item) => {
      const nPages = item.Pages;
      return [
        ...arr,
        ...new Array(nPages).fill(0).map((_v, i) => `${item.Name}_${i + 1}`),
      ];
    }, []);

    const trainerOnlyStages = items
      .filter((item) => item.TrainerOnly)
      .map((item) => item.Name);

    return delegates.flatMap((delegate) => {
      return keysForEachDelegate.map((k) => {
        const [name] = k.split("_");
        const delName = trainerOnlyStages.includes(name) ? "Trainer" : delegate;
        return `${delName}___${k}`;
      });
    });
  }, [items]);

  const [currentUserId, currentItemKey, currentItemType, currentItemPage] =
    useMemo(() => {
      if (!currentSubRoute) {
        return [null, null, null, null];
      }

      const [userId, type] = currentSubRoute.split("___");
      const [itemType, itemPage] = type.split("_");

      return [userId, type, itemType, itemPage];
    }, [currentSubRoute, allRequired]);

  const itemsReceived = useMemo(() => {
    return Object.keys(currentData).flatMap((delegate) => {
      return Object.keys(currentData[delegate])
        .filter((k) => !!currentData[delegate][k])
        .map((k) => {
          return `${delegate}___${k}`;
        });
    });
  }, [currentData]);

  const onComplete = useCallback(
    (photo) => {
      const [userId, key] = currentSubRoute.split("___");
      dispatch(
        addDelegateData({ data: { [key]: photo }, userId, doMerge: true }),
      );

      if (!photo) return;

      const currentIndex = allRequired.indexOf(currentSubRoute);
      const allItems = [
        ...allRequired.slice(currentIndex),
        ...allRequired.slice(0, currentIndex),
      ];

      const nextItem =
        allItems.find((item) => {
          return item !== currentSubRoute && !itemsReceived.includes(item);
        }) || null;

      dispatch(setSubRoute(nextItem));
    },
    [currentSubRoute, allRequired, itemsReceived],
  );

  const nRequired = useMemo(() => {
    const trainerItems = allRequired.filter(
      (k) => k.split("___")[0] === "Trainer",
    );

    const nTrainerItems = trainerItems.length;
    const nNonTrainerItems = allRequired.length - nTrainerItems;

    return nNonTrainerItems + nTrainerItems / (delegates?.length || 1);
  }, [allRequired, delegates?.length]);

  const isComplete = useMemo(() => {
    return itemsReceived.length >= nRequired;
  }, [nRequired, itemsReceived]);
  const name = useMemo(() => idToName(currentUserId), [currentUserId]);

  const groups = ["Trainer", ...delegates];

  return (
    <PageLayout>
      <div className="-mt-4 flex flex-col space-y-4">
        <H3>Paperwork</H3>
        <P>
          When the course is over, go through each candidate&apos;s paperwork
          and take pictures when complete.
        </P>
      </div>
      <div className="flex flex-col space-y-6">
        {groups.map((delegate) => {
          return (
            <PaperworkList
              delegate={delegate}
              allItems={allRequired}
              receivedItems={itemsReceived}
              key={delegate}
              onSelect={(id) => dispatch(setSubRoute(id))}
            />
          );
        })}
        {isComplete && (
          <div className="text-right">
            <Button
              variant="cta"
              onClick={() => dispatch(confirmStageCompletion())}
            >
              Next
            </Button>
          </div>
        )}
      </div>
      {!!currentSubRoute && stage?.Type === "PAPER" && (
        <WebcamVideo
          onPhoto={onComplete}
          onClose={() => dispatch(setSubRoute(null))}
          photo={currentData[currentUserId]?.[currentItemKey]}
        >
          <div className="absolute left-0 top-0 z-[12] flex flex-col space-y-2 bg-black/80 p-4 text-white">
            <span>
              {itemsReceived.length} / {nRequired} photos taken.
            </span>
            <span>
              Now take a photo of <strong className="font-bold">{name}</strong>{" "}
              paperwork, {currentItemType}: Page {currentItemPage}
            </span>
          </div>
        </WebcamVideo>
      )}
    </PageLayout>
  );
}
