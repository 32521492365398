export const courseQuestions = [
  {
    0: {
      Answer: "During use",
    },
    1: {
      Answer: "Before use",
    },
    2: {
      Answer: "After use",
    },
    3: {
      Answer: "That is the responsibility of the supervisor",
    },
    Description: "When should you first inspect your tools?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "During use",
      },
      {
        Answer: "Before use",
      },
      {
        Answer: "After use",
      },
      {
        Answer: "That is the responsibility of the supervisor",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "Skin disease",
    },
    1: {
      Answer: "Cardiovascular disease",
    },
    2: {
      Answer: "Hand arm vibration syndrome",
    },
    3: {
      Answer: "Seizures syndrome  ",
    },
    Description:
      "What is an illness associated with prolonged exposure to hand-held vibrating tools?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Skin disease",
      },
      {
        Answer: "Cardiovascular disease",
      },
      {
        Answer: "Hand arm vibration syndrome",
      },
      {
        Answer: "Seizures syndrome  ",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    Description: "What PPE needs to be worn when carrying out breaker tasks?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Glasses",
      },
      {
        Answer: "Impact Goggles & face shield",
      },
      {
        Answer: "Hard hat visor",
      },
      {
        Answer: "Sun glasses ",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    Description:
      "You find a defect with one of your tools, what is your next action?",
    Type: "CheckBox",
    Answers: [
      {
        Answer:
          "Carry on working as the defect is minor and shouldn't progress",
      },
      {
        Answer: "Repair the defect with some tape",
      },
      {
        Answer:
          "Stop, report the defect to your supervisor and make sure no one uses it ",
      },
      {
        Answer: "Leave the tool in the work area and go get another one",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    Description:
      "From the list below what is the best face mask to protect you from silica dust?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "FFP1",
      },
      {
        Answer: "FFP2",
      },
      {
        Answer: "FFP3",
      },
      {
        Answer: "FFP4",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    Description:
      "What should be in place if the noise level exceeds 85 decibels?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Ear protection ",
      },
      {
        Answer: "Escape route",
      },
      {
        Answer: "Nothing needs to be in place",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "Mains electrically powered",
    },
    1: {
      Answer: "",
    },
    2: {
      Answer: "",
    },
    Description: "What machine is typically the safest to use",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Mains electrically powered",
      },
      {
        Answer: "Battery powered",
      },
      {
        Answer: "Internal combustion engine powered",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "LOLER",
    },
    1: {
      Answer: "",
    },
    2: {
      Answer: "",
    },
    3: {
      Answer: "",
    },
    Description: "What regulation covers the use of handheld tools?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "LOLER",
      },
      {
        Answer: "COSHH",
      },
      {
        Answer: "NRSWA",
      },
      {
        Answer: "PUWER",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "Employers in the workplace",
    },
    1: {
      Answer: "Employees in the workplace",
    },
    2: {
      Answer: "Everyone in the workplace",
    },
    Description:
      "Health And Safety at Work Act 1974 places responsibilities on who?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Employers in the workplace",
      },
      {
        Answer: "Employees in the workplace",
      },
      {
        Answer: "Everyone in the workplace",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "CHOSC",
    },
    Description: "What regulation covers the use of hazardous substances?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "CHOSC",
      },
      {
        Answer: "COSHH",
      },
      {
        Answer: "SOCHH",
      },
      {
        Answer: "HOSCH",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    Description: "Why is it important to use an insulated mechanical breaker?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "To keep your hands warm",
      },
      {
        Answer: "To make excavating material easier",
      },
      {
        Answer: "To protect you incase of a cable strike",
      },
      {
        Answer: "To make the hand tool lighter",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "Dust suppression",
    },
    1: {
      Answer: "Stop material sticking to the whacker plate",
    },
    2: {
      Answer: "helps material stick to the machine ",
    },
    3: {
      Answer: "makes less noise ",
    },
    Description:
      "Why would you apply water when using a whacker plate on Tarmac? ",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Dust suppression",
      },
      {
        Answer: "Stop material sticking to the whacker plate",
      },
      {
        Answer: "helps material stick to the machine ",
      },
      {
        Answer: "makes less noise ",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "Operators have received a tool box talk",
    },
    1: {
      Answer: "",
    },
    2: {
      Answer: "",
    },
    Description: "What should you ensure prior to breaking ground?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Operators have received a tool box talk",
      },
      {
        Answer: "Permit has been filled out correctly ",
      },
      {
        Answer:
          "Permit has been filled out correctly, signed and briefed to all operatives",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "Will always show all services",
    },
    1: {
      Answer: "May not show all services",
    },
    2: {
      Answer: "will only show services laid after 1980",
    },
    Description:
      "Complete sentence correctly: Drawings supplied by the service provider...........................",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Will always show all services",
      },
      {
        Answer: "May not show all services",
      },
      {
        Answer: "will only show services laid after 1980",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "Anyone asked by the supervisor ",
    },
    1: {
      Answer: "",
    },
    2: {
      Answer: "",
    },
    Description: "Who can use power tools?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Anyone asked by the supervisor ",
      },
      {
        Answer: "Only trained and authorised personnel ",
      },
      {
        Answer: "Someone who has received their site induction",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    Description:
      "Why is it important to use gloves when handling oils or fuels?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "To prevent skin disease ",
      },
      {
        Answer: "To keep your hands clean",
      },
      {
        Answer: "Protects you from vibration",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    1: {
      Answer: "",
    },
    2: {
      Answer: "",
    },
    Description: "When first starting a petrol engine do you ",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "leave the choke open (on)",
      },
      {
        Answer: "leave the choke closed (off)",
      },
      {
        Answer: "it doesn't matter",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    Description: "What colour is a gas pipe?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Blue",
      },
      {
        Answer: "Red",
      },
      {
        Answer: "Black",
      },
      {
        Answer: "Yellow",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "",
    },
    Description: "What colour is a water pipe",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "Blue",
      },
      {
        Answer: "Red",
      },
      {
        Answer: "Black",
      },
      {
        Answer: "Yellow",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
  {
    0: {
      Answer: "After every use ",
    },
    Description: "How often should your power tools be serviced?",
    Type: "CheckBox",
    Answers: [
      {
        Answer: "After every use ",
      },
      {
        Answer: "As per manufactures maintenance schedule ",
      },
      {
        Answer: "Every 6 months ",
      },
      {
        Answer: "Every 9 months ",
      },
    ],
    _id: "643c0911099cad2fca1ee841",
  },
];
