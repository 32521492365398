import React from "react";

import "../css/swirl.css";
import { Header } from "./Header";
import { Logo } from "./Logo";

export function Container({ children, isSplashPage }) {
  return (
    <div className="relative h-full w-full">
      <div className="bg-gradient-radial relative flex h-full w-full from-blue-900 to-blue-950">
        <div className="swirl absolute left-0 top-0 z-10 h-full w-full" />
      </div>
      <div className="absolute left-0 top-0 z-20 flex h-full w-full flex-col">
        <Header isSplashPage={isSplashPage} />
        <Logo />
        <div className="relative h-full grow">{children}</div>
      </div>
    </div>
  );
}
