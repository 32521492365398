import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Question } from "./Question";
import { Button } from "./Button";

export function FormTheory({
  onSubmit,
  questions,
  answerableQuestions,
  defaultValues,
}) {
  const formMethods = useForm({
    defaultValues: defaultValues || {
      GivenAnswers: new Array(answerableQuestions.length).fill(-1),
    },
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} className="space-y-8">
        {questions.map((question, index) => {
          const i = answerableQuestions.indexOf(question);
          return (
            <Question
              n={i + 1}
              question={question}
              key={index}
              formKey={`GivenAnswers[${i}]`}
            />
          );
        })}
        <div className="text-right">
          <Button type="submit" variant="cta">
            Submit Answers
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
