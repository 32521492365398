import { authenticateAPI, authenticateFrontend } from "../slices/user";

export const authMiddleware = (store) => {
  let prev = 0;
  return (next) => (action) => {
    const now = new Date().getTime();
    const timeSinceAuth = now - prev;
    if (timeSinceAuth > 300000) {
      prev = now;
      authenticate(store.dispatch);
    }

    next(action);
  };
};

const authenticate = (dispatch) => {
  dispatch(authenticateFrontend());
  dispatch(authenticateAPI());
};
