import React from "react";
import { Button } from "./Button";

export function PageError({ error, info }) {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-6 text-blue-900">
      <p className="text-5xl">🙃 💥 🤬</p>
      <h1 className="text-4xl">Something went wrong.</h1>
      {!!error?.message && (
        <p className="text-xl">&quot;{error.message}&quot;</p>
      )}
      <Button variant="action" onClick={() => window.location.reload(true)}>
        Reload
      </Button>
      <a href="/debug.html" className="text-black underline" target="_blank">
        Diagnostics
      </a>
      {!!info?.componentStack && (
        <pre className="max-h-24 max-w-[90%] overflow-scroll border border-slate-100 bg-slate-50 p-2">
          {info.componentStack}
        </pre>
      )}
    </div>
  );
}
