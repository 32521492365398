import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { authenticateFrontend, authenticateAPI } from "../slices/user";
import { PageTrainerLogin } from "./PageTrainerLogin";
import { PageCourseList } from "./PageCourseList";
import { EditStageData } from "./EditStageData";
import { PageTrainerRegistration } from "./PageTrainerRegistration";
import { PageCandidatePhotos } from "./PageCandidatePhotos";
import { PageTrainerResults } from "./PageTrainerResults";
import { PageTrainerTheory } from "./PageTrainerTheory";
import { PageTrainerPractical } from "./PageTrainerPractical";
import { PageTrainerFeedback } from "./PageTrainerFeedback";
import { PagePaperwork } from "./PagePaperwork";
import { currentStage } from "../slices/trainer";
import { CourseCompleteTrainer } from "./CourseCompleteTrainer";
import { TransitionGroup } from "./TransitionGroup";
import { PageTrainerContents } from "./PageTrainerContents";
import { PageImages } from "./PageImages";

const pages = [
  PageTrainerLogin,
  PageCourseList,
  PageTrainerRegistration,
  PageCandidatePhotos,
  PageTrainerResults,
  PageTrainerTheory,
  PageTrainerPractical,
  PageTrainerFeedback,
  PagePaperwork,
  CourseCompleteTrainer,
  PageTrainerContents,
  PageImages,
];

export function RoutesTrainer() {
  const dispatch = useDispatch();
  const canViewFrontend = useSelector((state) => state.user.canViewFrontend);
  const showImagePage = useSelector((state) => state.trainer.showImagePage);
  const course = useSelector((state) => state.trainer.course);
  const stage = useSelector(currentStage);
  const hasCompletedContents = useSelector(
    (state) => state.trainer.hasCompletedContents,
  );

  useEffect(() => {
    dispatch(authenticateFrontend());
    dispatch(authenticateAPI());
  }, []);

  const pageIndex = useMemo(() => {
    if (!canViewFrontend) return 0;
    if (showImagePage) return 11;
    if (!course) return 1;
    if (!stage) return 9;
    return getCoursePageIndex(stage.Type, hasCompletedContents);
  }, [canViewFrontend, course, stage, hasCompletedContents, showImagePage]);

  return (
    <div className="relative h-full w-full">
      <EditStageData />
      <TransitionGroup pages={pages} pageIndex={pageIndex} />
    </div>
  );
}

const componentByOuterStage = {
  VORTEX_REGISTRATION: 2,
  CANDIDATE_PHOTOS: 3,
  RESULTS: 4,
  THEORY: 5,
  PRACTICAL: 6,
  FEEDBACK: 7,
  PAPER: 8,
};

function getCoursePageIndex(outerStage, hasCompletedContents) {
  console.log({ outerStage });
  if (
    (outerStage === "THEORY" || outerStage === "FEEDBACK") &&
    !hasCompletedContents
  ) {
    return 10;
  }
  const index = componentByOuterStage[outerStage];
  if (typeof index !== "number") {
    throw new Error(`Can't find component: ${outerStage}`);
  }

  return index;
}
