import React from "react";

export function MaskFace({ width, height, isMask, ...props }) {
  return (
    <ellipse
      cx="50%"
      cy="50%"
      rx={height * 0.55 * 0.5 * 0.75}
      ry={height * 0.55 * 0.5}
      {...props}
    />
  );
}
