import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import { Cross1Icon } from "@radix-ui/react-icons";

import { addDelegateData, edit, editData, editStage } from "../slices/trainer";
import { Link } from "./Link";
import { FormTheory } from "./FormTheory";
import { idToName } from "../utils";
import { H2 } from "./H2";
import { FormPractical } from "./FormPractical";

export function EditStageData() {
  const dispatch = useDispatch();
  const editPath = useSelector((state) => state.trainer.editPath);
  const data = useSelector(editData);
  const stage = useSelector(editStage);

  if (!data) return null;

  const Component = contentByType[stage.Type];
  if (!Component) return null;

  return createPortal(
    <div className="absolute top-14 z-[999] flex h-full w-full flex-col gap-6 overflow-y-auto bg-white p-4 pb-16 transition-opacity">
      <Link
        className="absolute right-0 top-0 p-4"
        onClick={() => dispatch(edit(null))}
      >
        <Cross1Icon className="h-6 w-6" />
      </Link>
      <Component
        data={data}
        stage={stage}
        stageIndex={editPath.stageIndex}
        userId={editPath.userId}
      />
    </div>,
    document.body,
  );
}

const contentByType = {
  THEORY,
  PRACTICAL,
};

function THEORY({ data, stage, userId, stageIndex }) {
  const dispatch = useDispatch();
  const name = idToName(userId);
  const questions = stage?.Details?.CourseQuestions;

  const answerableQuestions = useMemo(() => {
    return questions.filter(
      (q) => q.Type === "CheckBox" || q.Type === "MultipleChoice",
    );
  }, [questions]);

  return (
    <>
      <H2>Theory Results - {name}</H2>
      <FormTheory
        onSubmit={(data) => {
          dispatch(addDelegateData({ userId, data, stageIndex }));
          dispatch(edit(null));
        }}
        questions={questions}
        answerableQuestions={answerableQuestions}
        defaultValues={data}
      ></FormTheory>
    </>
  );
}

function PRACTICAL({ data, stage, userId, stageIndex }) {
  const dispatch = useDispatch();
  const name = idToName(userId);
  const questions = stage?.Details?.CourseQuestions;

  const answerableQuestions = useMemo(() => {
    return questions.filter(
      (q) => q.Type === "CheckBox" || q.Type === "MultipleChoice",
    );
  }, [questions]);

  return (
    <>
      <H2>Practical Results - {name}</H2>
      <FormPractical
        onSubmit={(data) => {
          dispatch(addDelegateData({ userId, data, stageIndex }));
          dispatch(edit(null));
        }}
        questions={questions}
        answerableQuestions={answerableQuestions}
        defaultValues={data}
      />
    </>
  );
}
