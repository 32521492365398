import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "./Button";
import { H2 } from "./H2";
import { P } from "./P";
import { QRCode } from "./QRCode";
import { toggleShowLatestStage, prevQRs } from "../slices/delegate";
import { PageLayout } from "./PageLayout";
import { MenuQR } from "./MenuQR";
import { EyeOpenIcon } from "@radix-ui/react-icons";

export function PageLatestStage() {
  const dispatch = useDispatch();
  const [stageToShow, setStageToShow] = useState(0);
  const qrs = useSelector(prevQRs);
  const userId = useSelector((state) => state.delegate.userId);
  const payload = {
    data: qrs[stageToShow]?.data,
    userId,
  };

  return (
    <PageLayout>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-2">
          <H2>Job Done</H2>
          <P>
            Wait for the trainer to scan the QR code below, then hit the Next
            button below.
            <MenuQR
              value={stageToShow}
              options={qrs}
              onChange={(i) => setStageToShow(i)}
            />
          </P>
        </div>
        <QRCode data={payload} />
        <div className="px-4 text-end">
          <Button
            variant="inconspic"
            onClick={() => dispatch(toggleShowLatestStage())}
          >
            <EyeOpenIcon />
            Trainer HAS Scanned My QR Code
          </Button>
        </div>
      </div>
    </PageLayout>
  );
}
