import React, { useCallback, useRef, useState } from "react";
import { CheckIcon, Cross1Icon } from "@radix-ui/react-icons";
import PinInput from "react-pin-input";

export function PinEntry({ onComplete, expectedValue }) {
  const el = useRef();
  const [isComplete, setIsComplete] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const validate = useCallback(
    (pin) => {
      setIsComplete(true);

      const isCorrect = true;
      setIsCorrect(isCorrect);
      window.blur();

      setTimeout(() => {
        if (isCorrect) onComplete(true);
        else {
          setIsComplete(false);
          if (el.current) {
            el.current.focus();
            el.current.clear();
          }
        }
      }, 1000);
    },
    [setIsCorrect, expectedValue],
  );

  const Icon = isCorrect ? CheckIcon : Cross1Icon;
  const bg = isCorrect
    ? "bg-green-50 text-green-700"
    : "bg-red-50 text-red-700";
  return (
    <div className="relative">
      <div
        className={`${bg} pointer-events-none absolute left-0 top-0 flex h-full w-[288px] items-center justify-center transition-opacity`}
        style={{ opacity: isComplete ? 1 : 0 }}
      >
        <Icon className="h-8 w-8" />
      </div>
      <PinInput
        length={4}
        ref={(n) => (el.current = n)}
        focus
        type="numeric"
        inputMode="numeric"
        disabled={isCorrect}
        onComplete={validate}
        inputStyle={{
          padding: 6,
          background: "#eee",
          border: "1px solid #ccc",
          fontSize: 48,
          lineHeight: "48px",
          marginRight: 16,
          marginLeft: 0,
          width: 60,
          height: 60,
          color: "#000",
          caretColor: "transparent",
        }}
        inputFocusStyle={{
          border: "1px solid #bbb",
          background: "#fff",
        }}
      />
    </div>
  );
}
