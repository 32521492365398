import React from "react";

import { Button } from "./Button";
import { H2 } from "./H2";
import { P } from "./P";
import { useDispatch } from "react-redux";
import { reset } from "../slices/shared";
import { PageLayout } from "./PageLayout";

export function CourseComplete() {
  const dispatch = useDispatch();

  return (
    <PageLayout>
      <div className="flex flex-col space-y-4">
        <H2>Course Complete</H2>
        <P>All work has been completed.</P>
        <div className="text-right">
          <Button variant="cta" onClick={() => dispatch(reset())}>
            Finish and reset
          </Button>
        </div>
      </div>
    </PageLayout>
  );
}
