import React, { useMemo } from "react";
import { QuestionCheckBox as CheckBox } from "./QuestionCheckBox";
import { QuestionText as Text } from "./QuestionText";

const components = {
  CheckBox,
  MultipleChoice: CheckBox,
  Text,
};

export function Question({ question, n, formKey }) {
  const Component = components[question.Type] || null;
  if (!Component) {
    return console.error("No question type", question.Type) || null;
  }

  const nAnswers = useMemo(() => {
    if (!question.Answers) return 1;
    return question.Answers.filter((a) => a.Points > 0);
  }, [question]);

  return (
    <div className="space-y-2">
      {n !== 0 && (
        <div className="flex flex-row items-center gap-2">
          <h4 className="text-4xl leading-none text-slate-300">{n}</h4>
          <legend className="leading-snug">
            {question.Description}{" "}
            {nAnswers > 1 && (
              <strong className="font-bold italic">({nAnswers} answers)</strong>
            )}
          </legend>
        </div>
      )}
      <Component {...question} formKey={formKey} />
    </div>
  );
}
