import React, { useState, useEffect } from "react";
import { PageLayout } from "./PageLayout";
import { useDispatch } from "react-redux";
import { uploadImage } from "../thunks/api";
import { toggleImagePage } from "../slices/trainer";
import { ExitIcon } from "@radix-ui/react-icons";
import { Link } from "./Link";

export function PageImages() {
  const images = useImages();
  const dispatch = useDispatch();

  return (
    <PageLayout>
      <div className="relative flex h-full w-full flex-col gap-4 overflow-y-auto overflow-x-hidden">
        <Link
          onClick={() => dispatch(toggleImagePage())}
          className="absolute right-4 top-4 rounded-full bg-white p-2"
        >
          <ExitIcon />
        </Link>
        {images.map((image, i) => {
          return (
            <>
              <img
                key={i}
                src={image.url}
                className="max-w-[90%]"
                onClick={() => dispatch(uploadImage(image.key))}
              />
              <button onClick={() => dispatch(uploadImage(image.key))}>
                Click to save it
              </button>
            </>
          );
        })}
      </div>
    </PageLayout>
  );
}

function useImages() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function run() {
      const cache = await caches.open("imageCache");
      const items = await cache.keys();

      const images = [];
      const keys = items.map((item) => item.url.split("/")[3]).filter(Boolean);

      for (let key of keys) {
        const matched = await cache.match(key);
        const blob = await matched.blob();

        images.push({
          url: URL.createObjectURL(blob),
          key,
        });
      }

      setImages(images);
    }

    run();
  }, [setImages]);

  return images;
}
