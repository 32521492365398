import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setStageData, currentStage } from "../slices/delegate";
import { FormTheory } from "./FormTheory";
import { H2 } from "./H2";
import { P } from "./P";
import { PinEntry } from "./PinEntry";
import { PageLayout } from "./PageLayout";

export function PageTheory() {
  const [pinCorrect, setPinCorrect] = useState(false);
  const stage = useSelector(currentStage);
  const dispatch = useDispatch();

  const questions = useMemo(() => {
    if (stage?.Type !== "THEORY" || !stage?.Details) return [];
    return stage.Details.CourseQuestions;
  }, [stage?.Details?.CourseQuestions, stage?.Type]);

  const answerableQuestions = useMemo(() => {
    return questions.filter(
      (q) => q.Type === "CheckBox" || q.Type === "MultipleChoice",
    );
  }, [questions]);

  return (
    <PageLayout>
      {stage?.Type === "THEORY" && !!stage?.Details ? (
        <div className="flex flex-col gap-4">
          <H2>{stage.Name}</H2>
          {pinCorrect ? (
            <FormTheory
              questions={questions}
              answerableQuestions={answerableQuestions}
              onSubmit={(data) => dispatch(setStageData(data))}
            />
          ) : (
            <>
              <P>
                To access your Theory questions, enter the pin given to you by
                your trainer below
              </P>
              <PinEntry
                onComplete={() => setPinCorrect(true)}
                expectedValue={stage.Details.Pin}
              />
            </>
          )}
        </div>
      ) : null}
    </PageLayout>
  );
}
