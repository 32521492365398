import {
  createSlice,
  current as currentObj,
  createSelector,
} from "@reduxjs/toolkit";
import deepEqual from "deep-equal";

import { reset } from "./shared";
import { idToName, markPractical, markTheory } from "../utils";
import {
  CANDIDATE_PHOTOS,
  RESULTS,
  VORTEX_REGISTRATION,
} from "../utils/dummyData";

const initialState = {
  course: null,
  stages: [],
  data: [],
  currentStage: 0,
  totalExpectedSteps: 0,
  subRoutes: [],
  delegates: [],
  notifications: [],
  pin: null,
  editPath: null,
  lastUpdated: 0,
  hasCompletedContents: 0,
  showImagePage: false,
};

export const trainerSlice = createSlice({
  name: "trainer",
  initialState,
  reducers: {
    createPin(state) {
      if (state.pin) return;
      state.pin = 1000 + Math.round(Math.random() * 8999);
    },
    // store value is used for "sub" routing only.
    setSubRoute(state, action) {
      const route = action.payload;
      if (!route) {
        state.subRoutes = [];
        return;
      }
      const prev = state.subRoutes.filter((v) => v !== route);
      state.subRoutes = [route, ...prev];
    },
    toggleImagePage(state) {
      state.showImagePage = !state.showImagePage;
    },
    selectCourse(state, action) {
      state.course = action.payload;
      state.stages = action.payload?.Stages?.filter(
        (s) => s.Type !== "FEEDBACK",
      );
      state.hasCompletedContents = false;
    },
    completeCourse(state) {
      state.hasCompletedContents = true;
    },
    back(state) {
      const [current, ...rest] = state.subRoutes;
      if (current) {
        state.subRoutes = rest;
      } else {
        if (state.editPath) {
          state.editPath = null;
        } else if (state.currentStage === 0) {
          // state.course = null;
          // state.stages = [];
          // state.data = [];
        } else {
          state.currentStage--;
          state.subRoutes = [];
        }
      }
    },
    addDelegateData(state, action) {
      const {
        userId,
        data,
        stageIndex = state.currentStage,
        doMerge,
      } = action.payload;

      const current = state.data[stageIndex] || {};
      const existingData = current[userId];
      const dataToAdd = doMerge ? { ...existingData, ...data } : data;
      const name = idToName(userId);
      const text = notifyText(name, state.stages[stageIndex]?.Type);

      const calculatedValues = calculateValues(
        state.stages[stageIndex],
        dataToAdd,
      );

      const nextData = {
        ...dataToAdd,
        ...calculatedValues,
      };

      if (
        !state.data[stageIndex]?.[userId] ||
        !deepEqual(nextData, currentObj(state.data[stageIndex][userId]))
      ) {
        if (text && data?.image !== null) {
          state.notifications.push({
            type: "SUCCESS",
            text,
          });
        }

        state.lastUpdated = new Date().getTime();
        state.data[stageIndex] = {
          ...current,
          [userId]: nextData,
        };
      }
    },
    confirmStageCompletion(state) {
      const totalRequired = nDelegates({ trainer: state });
      const stage = currentStageData({ trainer: state }) || {};
      const n = Object.keys(stage).length;
      if (n === totalRequired) {
        if (state.currentStage === 0) {
          state.delegates = Object.keys(stage);
        }

        state.subRoutes = [];
        state.currentStage++;
      }
    },
    notify(state, action) {
      const { text, type = "success" } = action.payload;
      state.notifications.push({ text, type });
    },
    edit(state, action) {
      if (!action.payload) state.editPath = null;
      else {
        const { userId, stageIndex = state.currentStage } = action.payload;
        state.editPath = { userId, stageIndex };
      }
    },
    debugPaperwork(state, action) {
      const prevCourse = state.course;
      const prevStages = state.stages.filter((s) => s.Type !== "FEEDBACK");

      state.data = initialState.data;
      state.course = prevCourse;
      state.stages = prevStages;

      state.data = prevStages
        .filter((s) => s.Type !== "PAPER")
        .map((stage) => {
          return getDummyData(stage, action.payload || 1);
        });

      state.currentStage = 3;
      const rego = getDummyData(
        { Type: "VORTEX_REGISTRATION" },
        action.payload || 1,
      );
      state.delegates = Object.keys(rego);
    },
  },
  extraReducers: (builder) => builder.addCase(reset, () => initialState),
});

export const isComplete = (state) => {
  if (!state.trainer.stages || !state.trainer.data) return false;
  const nToDo = state.trainer.stages.length;
  const nInto = state.trainer.data.length;
  if (nToDo === 0) return false;

  if (nInto !== nToDo) return false;

  return nDelegates(state) === totalDelegates(state);
};

export const nDelegates = (state) => {
  const index = Math.min(
    state.trainer.currentStage,
    state.trainer.stages.length - 1,
  );
  const curr = state.trainer.data[index] || {};
  return Object.keys(curr).filter((k) => {
    const v = curr[k];
    return !!v && v?.image !== null;
  }).length;
};

export const totalDelegates = (state) => {
  const reg = state.trainer.data[0] || {};
  return Object.keys(reg).length;
};

export const stages = (state) => state.trainer.stages;

export const nStages = createSelector([stages], (stages) => stages.length);

export const currentStageIndex = (state) => state.trainer.currentStage;

export const completionPercent = createSelector(
  [nDelegates, totalDelegates, nStages, currentStageIndex],
  (nDelegates, totalDelegates, nStages, currentStageIndex) => {
    const totalRequired = totalDelegates * nStages;

    const completedStageTotal = totalDelegates * currentStageIndex;
    if (totalRequired === 0) return 0;

    return ((completedStageTotal + nDelegates) / totalRequired) * 100;
  },
);

const EMPTY = {};

export const subRoute = (state) => {
  return state.trainer.subRoutes[0] || null;
};

export const currentStage = (state) => {
  return state.trainer.stages[state.trainer.currentStage];
};

export const currentStageData = (state) => {
  return state.trainer.data[state.trainer.currentStage] || EMPTY;
};

export const editData = (state) => {
  if (!state.trainer.editPath) return null;

  const { stageIndex, userId } = state.trainer.editPath;
  return state.trainer.data?.[stageIndex]?.[userId];
};

export const editStage = (state) => {
  if (!state.trainer.editPath) return null;

  const { stageIndex } = state.trainer.editPath;
  return state.trainer.stages[stageIndex];
};

export const delegateLiveCourse = createSelector(
  [
    (state) => state.trainer.course?.Stages,
    (state) => state.trainer.pin,
    (state) => state.trainer.course?._id,
  ],
  (stages, pin, courseId) => {
    return stages
      ?.map((stage) => {
        const fn = STAGE_TYPE_TO_DELEGATE_COURSE[stage.Type];
        if (!fn) return null;

        return fn(stage, pin, courseId);
      })
      .filter(Boolean);
  },
);

export const {
  selectCourse,
  confirmStageCompletion,
  addDelegateData,
  back,
  setSubRoute,
  createPin,
  notify,
  edit,
  completeCourse,
  debugPaperwork,
  toggleImagePage,
} = trainerSlice.actions;

export default trainerSlice.reducer;

const STAGE_TYPE_TO_DELEGATE_COURSE = {
  VORTEX_REGISTRATION(stage, pin, courseId) {
    return {
      ...stage,
      Details: {
        ...stage.Details,
        courseId,
      },
    };
  },
  FEEDBACK(stage) {
    return stage;
  },
  THEORY(stage, Pin) {
    if (stage.Details?.Type === "PAPER") return null;
    const {
      TotalToPass,
      DateCreated,
      CreatedBy,
      Updates,
      VersionHistory,
      Primary,
      _id,
      ...p
    } = stage.Details || {};

    if (!p.CourseQuestions) return stage;
    return {
      ...stage,
      Details: {
        ...p,
        Pin,
        CourseQuestions: p?.CourseQuestions?.map(({ _id, ...q }) => {
          return {
            ...q,
            Answers: q.Answers.map((a) => {
              return a.Answer;
            }),
          };
        }),
      },
    };
  },
};

function notifyText(name, type) {
  const byType = {
    VORTEX_REGISTRATION: `Registered ${name}`,
    THEORY: `${name} theory complete`,
    CANDIDATE_PHOTOS: `Saved ${name} photo`,
    PRACTICAL: `${name} practical complete`,
  };

  return byType[type] || null;
}

function calculateValues(stage, data) {
  const byType = {
    THEORY(stage, data) {
      if (stage.Details.Type === "DIGITAL") {
        return markTheory(stage, data);
      }

      return {};
    },
    PRACTICAL(stage, data) {
      return markPractical(stage, data);
    },
  };

  const typeFn = byType[stage.Type] || (() => ({}));
  return typeFn(stage, data);
}

function getDummyData(stage, nDelegates) {
  return DUMMY_FNS[stage.Type](nDelegates);
}

const DUMMY_FNS = {
  VORTEX_REGISTRATION: VORTEX_REGISTRATION,
  CANDIDATE_PHOTOS: CANDIDATE_PHOTOS,
  RESULTS: RESULTS,
};
