import { useCallback, useEffect, useState } from "react";

export function useWorker() {
  const [worker, setWorker] = useState(null);
  const [result, setResult] = useState(null);

  useEffect(() => {
    const w = new Worker(new URL("../workers/index.js", import.meta.url));
    setWorker(w);

    return () => w.terminate();
  }, []);

  useEffect(() => {
    if (!worker) return;
    worker.onmessage = (e) => {
      setResult(e.data);
    };
    worker.onerror = console.error;
  }, [worker]);

  const postMessage = useCallback(
    (fn, data) => {
      worker?.postMessage({ fn, data });
    },
    [worker],
  );

  return [result, postMessage];
}
