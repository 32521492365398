import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { H3 } from "./H3";
import { P } from "./P";
import { currentStage, completeCourse } from "../slices/trainer";
import { PageLayout } from "./PageLayout";
import { Button } from "./Button";

export function PageTrainerContents() {
  const stage = useSelector(currentStage);
  const dispatch = useDispatch();

  return (
    <PageLayout>
      <div className="-mt-4 flex flex-col space-y-4">
        <H3>It&apos;s Course Time!</H3>
        <P>
          Registration has been completed successfully. Delegates can put down
          their devices and so can you.
        </P>
        <P>Hit the button below when the course is finished.</P>
        <div className="text-right">
          <Button variant="cta" onClick={() => dispatch(completeCourse())}>
            Next: {stage?.Name}
          </Button>
        </div>
      </div>
    </PageLayout>
  );
}
