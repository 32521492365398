import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import idbstorage from "redux-persist-indexeddb-storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import thunk from "redux-thunk";

import userReducer from "../slices/user";
import trainerReducer from "../slices/trainer";
import delegateReducer from "../slices/delegate";
import { imageCache } from "../middleware/imageCache";
import { syncMiddleware } from "../middleware/sync";
import { authMiddleware } from "../middleware/auth";
import { backupMiddleware } from "../middleware/backup";
import apiReducer from "../slices/api";

const persistConfig = (key, useidb, ...blacklist) => ({
  key,
  storage: useidb ? idbstorage("vortalapi") : storage,
  blacklist: ["notifications", "errors", ...blacklist],
});

const trainer = persistReducer(persistConfig("trainer", false), trainerReducer);
const delegate = persistReducer(
  persistConfig("delegate", false),
  delegateReducer,
);

const api = persistReducer(persistConfig("api", true), apiReducer);

export const store = configureStore({
  reducer: {
    user: userReducer,
    trainer,
    delegate,
    api,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      imageCache,
      thunk,
      backupMiddleware,
      syncMiddleware,
      authMiddleware,
    ]),
});

export const persistor = persistStore(store);
