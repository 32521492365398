import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { putDump } from "../thunks/api";
import {
  HamburgerMenuIcon,
  SymbolIcon,
  PersonIcon,
  ExclamationTriangleIcon,
  ExitIcon,
  RocketIcon,
  ImageIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";

import { Link } from "./Link";
import { reset } from "../slices/shared";
import { setUserType, logout } from "../slices/user";
import { Confirm } from "./Confirm";
import { toggleImagePage } from "../slices/trainer";
import { addTheory } from "../slices/delegate";

export function Menu() {
  const [confirm, setConfirm] = useState(null);
  const [container, setContainer] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.type);
  const isAuthenticated = useSelector((state) => state.user.canViewFrontend);
  const hasTheory = useSelector((state) =>
    state.delegate.stages?.find((s) => s.Type === "THEORY"),
  );
  const courseId = useSelector(
    (state) => state.delegate.stages?.[0]?.Details?.courseId,
  );

  return (
    <>
      {!!confirm && <Confirm onCancel={() => setConfirm(null)} {...confirm} />}
      <DropdownMenu.Root onOpenChange={setIsOpen} open={isOpen} modal={true}>
        <DropdownMenu.Trigger asChild>
          <Link onClick={() => setIsOpen(!isOpen)} className="p-4">
            <HamburgerMenuIcon className="h-6 w-6 text-white" />
          </Link>
        </DropdownMenu.Trigger>
        <div
          className="fixed left-0 top-0 !z-[99999999]"
          ref={setContainer}
        ></div>
        <DropdownMenu.Portal container={container}>
          <DropdownMenu.Content
            align="right"
            className="!z-[99999999] bg-blue-950 shadow-lg"
          >
            <Item
              onClick={() => {
                window.location.reload(true);
              }}
            >
              <SymbolIcon className="h-4 w-4" />
              Refresh
            </Item>
            {userType === "TRAINER" ? (
              <Item onClick={() => dispatch(setUserType("DELEGATE"))}>
                <PersonIcon className="h-4 w-4" />
                Switch to delegate
              </Item>
            ) : (
              <Item onClick={() => dispatch(setUserType("TRAINER"))}>
                <PersonIcon className="h-4 w-4" />
                Login as trainer
              </Item>
            )}
            <Item
              onClick={() => {
                dispatch(putDump());
              }}
            >
              <RocketIcon className="h-4 w-4" />
              Send Diagnostic Report
            </Item>
            <Item
              onClick={() =>
                setConfirm({
                  onConfirm: () => dispatch(reset(true)),
                  description:
                    "All progress from the course will be lost. Are you absolutely sure you want to do this?",
                  title: "Reset everything?",
                })
              }
            >
              <ExclamationTriangleIcon className="h-4 w-4" />
              Reset everything
            </Item>
            {userType === "TRAINER" && (
              <Item onClick={() => dispatch(toggleImagePage())}>
                <ImageIcon className="h-4 w-4" />
                View Images
              </Item>
            )}
            {!hasTheory && courseId === "65eb4dd81af5dedf8d09ed00" && (
              <Item onClick={() => dispatch(addTheory())}>
                <QuestionMarkCircledIcon />
                Theory Test
              </Item>
            )}
            {isAuthenticated && (
              <Item
                onClick={() =>
                  setConfirm({
                    onConfirm: () => dispatch(logout()),
                    description:
                      "If you logout you won't be able to sign back in without an internet connection",
                    title: "Are you sure?",
                  })
                }
              >
                <ExitIcon className="h-4 w-4" />
                Logout
              </Item>
            )}
            <DropdownMenu.Arrow className="fill-blue-950" />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
}

function Item({ children, onClick }) {
  return (
    <DropdownMenu.Item
      onClick={onClick}
      className="flex cursor-pointer items-center justify-start gap-x-2 border-b border-black/80 p-3 text-sm text-white hover:bg-black/80 focus:bg-black/80 focus:outline-none"
    >
      {children}
    </DropdownMenu.Item>
  );
}
