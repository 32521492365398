import React, { useEffect } from "react";

import { H2 } from "./H2";
import { H3 } from "./H3";
import { P } from "./P";
import { Button } from "./Button";
import { Link } from "./Link";

export function Terms({ onComplete, onCancel }) {
  useEffect(() => {
    const container = document.getElementById("container");
    if (!container) return;

    container.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <H2>Vortex Training Group Limited - Terms & Conditions for Training</H2>

      <H3>Introduction</H3>

      <P>
        These Terms & Conditions (&quot;Terms&quot;) govern the provision of
        training services by Vortex Training Group Limited (&quot;Vortex&quot;,
        &quot;we&quot;, &quot;us&quot;) to you (&quot;Client&quot;,
        &quot;you&quot;). Please read these Terms carefully before enrolling in
        any training program offered by Vortex. By enrolling in a training
        program, you agree to be bound by these Terms.
      </P>

      <H3>1. Definitions </H3>

      <P>
        “Company” Vortex Training Group Limited , a company registered in
        England and Wales with Company Registration No: 11006843 having its
        registered office at Registered Office: Brown Hill Farm, Copthurst Lane,
        Whittle-le-Woods, Chorley, Lancashire, PR6 8LR, United Kingdom; “Client”
        the entity to whom the Training Services are provided as set out in the
        Company’s quote issued pursuant to clause 2.1; “Fee” the fee(s) payable
        for the Training Services as set out in the Company’s quote issued
        pursuant to clause 2.1; “Intellectual Property” any and all intellectual
        property rights of any nature anywhere in the world whether registered,
        registerable or otherwise, including without limitation patents, rights
        in inventions, trademarks, rights in designs, domain names, trade or
        business names, goodwill, copyright and rights in the nature of
        copyright, rights in databases, moral rights, know-how, confidential
        information and any other intellectual property rights; “Training
        Services” the training services provided by the Company to the Client
        (which for the avoidance of doubt may be provided to the Client’s
        personnel) via publicly accessible courses or in-company courses, as
        further set out in the Company’s quote issued pursuant to clause 2.1.{" "}
      </P>

      <H3>2. Contract Formation and Governance</H3>

      <P>
        2.1 Upon request from the Client, the Company may issue a quote to the
        Client for Training Services. The quote shall constitute an invitation
        for the Client to offer to purchase the Training Services subject to
        these terms and conditions and the quote terms. The Client’s verbal
        confirmation of the Company’s quote, or the issue of a purchase order or
        other written request by the Client for the Training Services, shall
        constitute the Client’s offer which may (at the Company’s sole
        discretion) be accepted by the Company by issuing the Client with
        written joining instructions for the Training Services. Upon the
        Company’s acceptance of the Client’s offer as described above, a
        contract shall be formed upon the terms of the quote provided to the
        Client by the Company, these terms and conditions and the written
        joining instructions for the Training Services (“Contract Documents”).
        Where there is a conflict between any of the Contract Documents, the
        following documents shall take precedence in order of decreasing
        priority:{" "}
      </P>

      <P>
        2.1.1 these terms and conditions 2.1.2 the Company’s quote; and 2.1.3
        the Company’s joining instructions.{" "}
      </P>

      <P>
        2.2 Should any information or data supplied to the Company for the
        preparation of the quotation (pursuant to clause 2.1) prove to be
        insufficient or inaccurate, the Company reserves the right to amend the
        quotation to cover any cost difference.{" "}
      </P>

      <P>
        2.3 These terms and conditions apply to the exclusion of any other terms
        proposed by the Client (whether set out in any Client purchase order or
        otherwise) and the Client expressly acknowledges that the Training
        Services shall be provided in accordance with the Contract Documents to
        the exclusion of any other terms. The Contract Documents constitute the
        entire agreement between the parties and supersede all previous
        agreements, understandings, and arrangements between them, whether oral
        or written, in respect of their subject matter. Each party acknowledges
        that it has not entered into the contract in reliance on, and shall have
        no remedies in respect of, any representation or warranty that is not
        expressly set out in the Contract Documents.{" "}
      </P>

      <P>3. Training Services – Client Requirements </P>

      <P>
        3.1 The Client shall ensure that its delegates will comply with all of
        the Company’s policies at all times during the provision of Training
        Services.{" "}
      </P>

      <P>
        3.2 Where the Training Services being provided are not solely
        theoretical, the Client is responsible for ensuring all its delegates
        provide their own appropriate personal protective equipment, and the
        Company will not be liable for any failure of any delegate to provide
        such equipment. Client delegates must be physically fit to withstand the
        rigours of training, particularly in excavations. If there are any
        doubts relating to the fitness of Client delegates to undertake
        training, advice should be sought from a qualified medical practitioner
        prior to the commencement of the course. The onus is entirely with the
        Client delegate to ensure his or her fitness to undergo training before
        booking on to any course. The Company reserves the right to refuse entry
        to any of the Client’s delegates if, in the reasonable opinion of the
        Company, the requirements of this clause are not met. For the avoidance
        of doubt, the Client will remain liable for all Fees in these
        circumstances.{" "}
      </P>

      <P>
        3.3 Any equipment used in the provision of Training Services must not be
        removed or moved from its original location without the consent of the
        Company. Any damage caused by a client delegate to such equipment by
        wilful misuse or misconduct will be invoiced to (and paid by) the
        Client.{" "}
      </P>

      <P>
        3.4 - Training certificates are awarded at the trainer&apos;s discretion
        to Client delegates who successfully completed both theoretical and
        practical assessments (when applicable). These certificates reflect the
        specific skills and knowledge gained in the training program. For
        sessions focused on participation rather than assessment, certificates
        acknowledging attendance and active engagement may be issued upon prior
        agreement. The wording of all certificates will be tailored to
        accurately reflect the nature and content of the training delivered.
      </P>

      <P>
        3.5 Client delegates are required to be punctual at all courses and
        sessions. Client delegates arriving late may be refused entry onto the
        course. The Client will remain liable for all Fees where Client
        delegates arrive after the course has commenced and have been refused
        entry.{" "}
      </P>

      <P>
        3.6 Consumption of alcohol and drugs are not permitted prior to or
        during training. If the Company is of the reasonable opinion that a
        Client delegate is intoxicated or under the influence of drugs, trainers
        are obliged to refuse to train any such delegates. The Client will
        remain liable for all Fees in these circumstances.{" "}
      </P>

      <P>
        3.7 Where Training Services are provided at the Client’s premises, the
        Client shall ensure that the facilities shall be adequate for the
        Training Services to take place (including, but not limited to size of
        the training area). The Company shall be entitled to refuse to provide
        the Training Services where the requirements of this clause 3.7 are not
        met. The Client will remain liable for all Fees in these circumstances.{" "}
      </P>

      <H3>4. Intellectual Property </H3>

      <P>
        All Intellectual Property rights existing in any course materials
        provided to Client (or Client delegates) during the Training Services
        belong to the Company and/or its licensors. Client and Client delegates
        have a right to use the said materials (but not copy them) for the
        Client’s internal and non-commercial business purposes only. For the
        avoidance of doubt the Client is not permitted to use any course
        materials to perform training itself.{" "}
      </P>

      <H3>5. Price & Payment Terms </H3>

      <P>
        5.1 The Client shall pay the applicable Fees for the Training Services.
        All Fees are subject to Value Added Tax (if applicable) at the
        appropriate rate ruling from time to time.{" "}
      </P>

      <P>
        5.2 All Fees must be paid in full, cleared funds by no less than 14 days
        prior to the start date of the Training Services to which they relate
        (unless the Client has a credit account in which case clause 5.4 below
        will apply).{" "}
      </P>

      <P>
        5.3 Payment can be made by bacs, credit/debit cards at the time of
        booking or on receipt of invoice (which may be issued through the
        Company’s automated invoicing system).{" "}
      </P>

      <P>
        5.4 The Company may (at its sole discretion) provide Clients with
        approved credit accounts and set a reasonable credit limit in respect
        thereof. The Company may, in its sole discretion, reduce the Client’s
        credit limit or cancel the Client’s credit account at any time on
        notice. Unless otherwise agreed in any quotation, Clients with credit
        accounts shall pay the Fees within 30 days of the date of the Company’s
        invoice.{" "}
      </P>

      <P>
        5.5 Where the Client fails to pay Fees in accordance with this clause 5,
        the Company reserves the right to suspend performance of the Training
        Services, and retain the relevant governing body cards/certificates,
        until the overdue Fees are paid in full.{" "}
      </P>

      <P>
        5.6 The Company reserves the right to charge costs and interest on
        overdue Fees from the due date for payment until actual payment in full,
        whether before or after judgment, under the Late Payment of Commercial
        Debts (Interest) Act 1998 at the applicable rate from time to time in
        force. Interest shall accrue on a daily basis.{" "}
      </P>

      <H3>6. Health & Safety </H3>

      <P>6.1 The Client shall ensure that: </P>

      <P>
        6.1.1 any premises in which the Company&apos;s employee’s servants or
        agents provide the Training Services are safe for that purpose. All
        known risks must be clearly identified, marked and appropriately managed
        by the Client; 6.1.2 where the Company has agreed to provide food during
        the Training Services, the Client notifies the Company in advance of any
        food allergies of Client delegates attending the Training Services; and
        6.1.3 it has notified the Company in advance of any learning
        difficulties or physical disabilities of any Client delegate attending
        the Training Services.{" "}
      </P>

      <P>
        and the Company reserves the right to cancel the Training Services if,
        in the reasonable opinion of the Company, the Client has not complied
        with this clause 6.1. For the avoidance of doubt the full Fee remains
        payable in such circumstances.{" "}
      </P>

      <P>
        6.2 The notice to be provided by the Client under clauses 6.1.2 and
        6.1.3 above shall not be less than 14 days prior to the commencement of
        the course.{" "}
      </P>

      <H3>7. Cancellations, Postponements and Transfers </H3>

      <P>
        7.1 Training Services may only be cancelled if the Client pays the
        following cancellation fees. Notice of all cancellations or transfers
        must be in writing.{" "}
      </P>

      <P>
        7.1.1 Publicly available courses: 7.1.1.2 cancellation 22 working days
        or more prior to the course start date: No Fee; 7.1.1.3 cancellation
        21-15 working days prior to course start date: 50% Fee payable; 7.1.1.4
        cancellation within 14 days or less prior to course start date: Full Fee
        payable.{" "}
      </P>

      <P>7.1.2 ‘In-company courses’: </P>

      <P>
        7.1.2.2 Cancellation 22 working days or more prior to the course start
        date: No Fee; 7.1.2.3 cancellation 21-15 working days prior to course
        start date: 50% Fee payable; 7.1.2.4 cancellation within 14 working days
        or less prior to course start date: Full Fee payable.
      </P>

      <P>
        7.2 Subject to clause 5.5, where the applicable Fee is not paid on time
        but the (publicly available) course booking has not been expressly
        cancelled by the Client, the Company reserves the right to re-allocate
        the course places to other non-Client delegates on the waiting list. In
        such circumstances the full Fee remains payable.{" "}
      </P>

      <P>
        7.3 Subject to clause 7.2, substitute Client delegates are permitted to
        attend the Training Services with no cancellation charge.{" "}
      </P>

      <P>
        7.4 If, as a result of any reason beyond its reasonable control, the
        Company cancels a Training Services course without notice to the Client,
        the Company will not be held liable for any costs incurred by the Client
        as a result of such cancellation. Should it become necessary for the
        Company to cancel or postpone all or any part of a course due to any
        reason beyond its reasonable control, the Company shall use reasonable
        endeavours to agree an alternative date with the Client on which the
        Training Services will be provided. The Company will not be liable for
        any costs incurred by the Client in the circumstances described in this
        clause 7.4. Without prejudice to the foregoing, the Company may cancel a
        Training Services course if in its reasonable opinion adverse weather
        conditions will put the trainer or delegates at risk.{" "}
      </P>

      <P>
        7.5 Where a client delegate notifies the Company of his or her intention
        to transfer a course booking to a later course, the Company may, at its
        sole discretion, use reasonable endeavours to accommodate reasonable
        transfer requests subject to places being available on the alternative
        course. Where the request was received by the Company 14 days or less
        prior to commencement of the course originally booked, the full Fee for
        the originally booked course shall be payable by the Client (in addition
        to the Fee for any course to which the booking is transferred).{" "}
      </P>

      <P>
        7.6 All cancellation charges shall become payable immediately at the
        point of cancellation.{" "}
      </P>

      <H3>8. Liability </H3>

      <P>
        8.1 Notwithstanding any other provision of these terms and conditions,
        the liability of the parties shall not be limited in any way in respect
        of any losses which cannot be excluded or limited by applicable law.{" "}
      </P>

      <P>
        8.2 Subject to clause 8.1, the Company shall not be liable for any loss
        of profit, data, savings, or goodwill (whether such losses are direct or
        indirect), or for any consequential, indirect or special losses.{" "}
      </P>

      <P>
        8.3 Training Services will be provided with reasonable care and skill.
        Subject to the foregoing, all other warranties, and conditions whether
        express or implied by statute, common law or otherwise are excluded to
        the fullest extent permitted by law.{" "}
      </P>

      <H3>9. Course Content </H3>

      <P>
        9.1 The Company consistently develops the Training Services in line with
        awarding body criteria and evolving best practice and therefore reserves
        the right to make changes to the advertised course content without prior
        notice to the Client.{" "}
      </P>

      <H3>10. Data Protection </H3>

      <P>
        10.1 Each party shall always comply with all applicable data protection
        legislation during the term of the contract. For the purposes of such
        data protection legislation, the Company is a controller of the personal
        data of the Client’s delegates that it receives pursuant to the Training
        Services, and the Company shall process that personal data in accordance
        with its privacy policy which is available at
        https://www.vortexgroup.co.uk/{" "}
      </P>

      <H3>11. Termination </H3>

      <P>
        11.1 Either party may terminate the contract at any time by giving
        written notice to the other if:{" "}
      </P>

      <P>
        11.1.1 The other party commits a material breach of the contract (which,
        if capable of remedy, is not remedied within 14 days of notice in
        writing to do so); 11.1.2 the other party stops carrying on all or a
        significant part of its business; 11.1.3 the other party is unable to
        pay its debts within the meaning of section 123 of the Insolvency Act
        1986; 11.1.4 the other party is subject to a company voluntary
        arrangement under the Insolvency Act 1986 11.1.5 the other party has a
        receiver, manager, administrator or administrative receiver appointed
        over all or any part of its undertaking, assets or income; 11.1.6 the
        other party has a resolution passed for its winding up, a petition is
        presented to any court for its winding up or an application is made for
        an administration order, or any winding-up or administration order is
        made against it.{" "}
      </P>

      <H3>12. General </H3>

      <P>
        12.1 Variation. No variation of these terms and conditions will bind the
        Company unless such variation is expressly agreed in writing and signed
        by an authorised representative of the Company.{" "}
      </P>

      <P>
        12.2 Assignment and subcontracting. The Company may assign or
        subcontract any of its rights or obligations under the contract in whole
        or in part at any time.{" "}
      </P>

      <P>
        12.3 Rights of third parties. Any person who is not a party to the
        contract shall not have any rights under the Contracts (Rights of Third
        Parties) Act 1999 to enforce any of its provisions.{" "}
      </P>

      <P>
        12.4 Set off. The Client shall pay all Fees due to the Company without
        any set-off, counterclaim, deduction or withholding of any kind.{" "}
      </P>

      <P>
        12.5. Interpretation. In these terms and conditions “written” include
        email.{" "}
      </P>

      <P>
        12.6. Applicable Law. The contract shall be governed by English law and
        the parties hereby submit to the exclusive jurisdiction of the English
        Courts.{" "}
      </P>

      <div className="mb-4 mt-6 flex justify-between align-middle">
        <Link onClick={onCancel}>&lt; back</Link>
        <Button variant="cta" onClick={() => onComplete(new Date().getTime())}>
          I agree to the Terms &amp; Conditions
        </Button>
      </div>
    </div>
  );
}
