import React from "react";

export function PageLayout({ children }) {
  return (
    <div
      className="shadow-page h-full w-full overflow-y-auto bg-white p-4"
      id="container"
    >
      {children}
    </div>
  );
}
