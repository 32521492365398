import { createSlice } from "@reduxjs/toolkit";

import { reset } from "./shared";
import { toUserId } from "../utils/toUserId";
import { courseQuestions } from "./tmp_smalltools";

const initialState = {
  stages: null,
  stageData: [],
  showLatestStage: false,
  userId: null,
  transitionsComplete: ["FEEDBACK", "THEORY"], // ADDED TO PREVENT TRANSITIONS AS A TEST RUN.
};

export const delegateSlice = createSlice({
  name: "delegate",
  initialState,
  reducers: {
    login(state, action) {
      state.stages = action.payload;
    },
    completeTransition(state, action) {
      state.transitionsComplete.push(action.payload);
    },
    setStageData(state, action) {
      const data = action.payload;
      const currentStage = state.stages[state.stageData.length];
      if (currentStage.Type === "VORTEX_REGISTRATION") {
        state.userId = toUserId(data);
      }

      state.showLatestStage = true;
      state.stageData.push(data);
    },
    addTheory(state) {
      const reg = state.stages[0];
      const feedback = state.stages.find((s) => s.Type === "FEEDBACK");
      const theory = {
        Name: "Theory",
        Type: "THEORY",
        Details: {
          CourseQuestions: courseQuestions.map((q) => {
            return {
              ...q,
              Answers: q.Answers.map((a) => {
                return a.Answer;
              }),
            };
          }),
          TotalToPass: 8,
          Primary: true,
        },
      };

      state.stageData = state.stageData.filter((s) => !s.GivenAnswers);

      state.stages = [reg, theory, feedback];
    },
    toggleShowLatestStage(state) {
      state.showLatestStage = !state.showLatestStage;
    },
  },
  extraReducers: (builder) => builder.addCase(reset, () => initialState),
});

export const {
  login,
  setStageData,
  toggleShowLatestStage,
  completeTransition,
  addTheory,
} = delegateSlice.actions;

export default delegateSlice.reducer;

export const currentStage = (state) => {
  if (!state.delegate.stages?.length) return null;
  return state.delegate.stages[state.delegate.stageData.length];
};

export const prevQRs = (state) => {
  return state.delegate.stageData
    .map((d, i) => {
      const stage = state.delegate.stages[i];
      return {
        name: stage.Name,
        data: d,
      };
    })
    .reverse();
};

export const latestStage = (state) => {
  return state.delegate.stageData[state.delegate.stageData.length - 1];
};

export const shouldShowTransition = (state) => {
  if (
    state.user.type !== "DELEGATE" ||
    !state.delegate.stageData ||
    !state.delegate.stages
  )
    return false;

  if (
    state.delegate.stageData.length === state.delegate.stages.length &&
    !state.delegate.transitionsComplete.includes("FINAL")
  ) {
    return "FINAL";
  }

  const nextStage = currentStage(state);
  if (!nextStage?.Type) return false;

  const STAGES_WITH_TRANSITIONS_BEFORE = ["THEORY", "FEEDBACK"];
  return STAGES_WITH_TRANSITIONS_BEFORE.includes(nextStage.Type) &&
    !state.delegate.transitionsComplete.includes(nextStage.Type)
    ? nextStage.Type
    : false;
};

export const delegateName = (state) => {
  const data = state.delegate.stageData || [];
  const [rego] = data;
  if (!rego) return null;

  return rego.FirstName && rego.Surname
    ? `${rego.FirstName} ${rego.Surname}`
    : null;
};
