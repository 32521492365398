import React, { useState, useRef, useLayoutEffect, useEffect } from "react";

import { VideoFeed } from "./VideoFeed";
import { useScreenSize } from "../hooks/useScreenSize";
import { Mask } from "./Mask";
import { Button } from "./Button";
import { Link } from "./Link";
import { CheckIcon, Cross1Icon, TrashIcon } from "@radix-ui/react-icons";
import { CacheImage } from "./CacheImage";
import { createPortal } from "react-dom";

export function WebcamVideo({
  onComplete,
  onPhoto,
  photo,
  children,
  readQRs,
  onClose,
  mask,
}) {
  const [isActive, setIsActive] = useState(false);
  const [tmpPhoto, setTmpPhoto] = useState(photo);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const screenSize = useScreenSize();

  const containerRef = useRef(null);

  useEffect(() => {
    setTmpPhoto(photo);
  }, [photo]);

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const { width, height } = containerRef.current.getBoundingClientRect();
    setContainerWidth(width);
    setContainerHeight(height);
  }, [screenSize]);

  return (
    <div className="absolute left-0 top-0 z-30 h-full w-full bg-black pt-1.5">
      {!!onClose && (
        <button
          className="absolute right-0 top-0 z-40 border-none bg-none p-4 text-white outline-none"
          onClick={onClose}
        >
          <Cross1Icon className="h-8 w-8" />
        </button>
      )}
      <div
        className="absolute left-0 top-0 z-10 h-full w-full"
        ref={containerRef}
      >
        {tmpPhoto ? (
          tmpPhoto === photo ? (
            <CacheImage
              imageKey={photo}
              className="absolute left-1/2 top-1/2 max-h-full max-w-full -translate-x-1/2 -translate-y-1/2"
            />
          ) : (
            <div className="absolute left-1/2 top-1/2 max-h-full max-w-full -translate-x-1/2 -translate-y-1/2">
              <img
                src={tmpPhoto.img}
                width={tmpPhoto.w}
                height={tmpPhoto.h}
                className="max-w-none"
                style={{
                  width: tmpPhoto.cssw,
                  height: tmpPhoto.cssh,
                }}
              />
            </div>
          )
        ) : (
          <>
            <VideoFeed
              readQRs={readQRs}
              onComplete={onComplete}
              containerRef={containerRef}
              snapper={!!onPhoto && !tmpPhoto && Snapper(setTmpPhoto)}
              onActive={setIsActive}
            />
            {!!mask && (
              <Mask
                type={mask}
                isActive={isActive}
                width={containerWidth}
                height={containerHeight}
              />
            )}
          </>
        )}
      </div>
      {children}
      {!!tmpPhoto && (
        <div className="absolute bottom-0 left-0 z-[100] flex w-full flex-row justify-between bg-black/80 p-4 text-white">
          <>
            <Link
              className="flex flex-row items-center gap-x-1 text-white underline"
              onClick={() => {
                setTmpPhoto(null);
                onPhoto(null);
              }}
            >
              <TrashIcon className="h-4 w-4" />
              Discard
            </Link>
            <div>
              <Button
                className="!px-2 !py-1"
                onClick={() => {
                  onPhoto(tmpPhoto?.img);
                  setTmpPhoto(null);
                }}
              >
                <CheckIcon className="h-4 w-4" />
                Confirm Photo
              </Button>
            </div>
          </>
        </div>
      )}
    </div>
  );
}

function Snapper(setTmpPhoto) {
  return function SnapComponent(snap) {
    return createPortal(
      <div className="fixed bottom-8 left-1/2 z-[99] h-16 w-16 -translate-x-1/2 rounded-full border-4 border-white bg-black p-0.5 shadow-md">
        <button
          className="h-full w-full rounded-full border border-black bg-white"
          onClick={() => setTmpPhoto(snap())}
        />
      </div>,
      document.body,
    );
  };
}
