import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CaretDownIcon,
  CaretUpIcon,
  CrossCircledIcon,
} from "@radix-ui/react-icons";

import { Button } from "./Button";
import {
  currentStageData,
  confirmStageCompletion,
  nDelegates,
} from "../slices/trainer";
import { idToName } from "../utils";

const defaultColorFn = () => "bg-emerald-50";

export function TrainerDelegateList({
  content: Content,
  isComplete,
  onSelect,
  buttonText,
  colorFn = defaultColorFn,
}) {
  const dispatch = useDispatch();
  const delegateIds = useSelector((state) => state.trainer.delegates);

  const [openId, setOpenId] = useState(null);
  const current = useSelector(currentStageData);
  const n = useSelector(nDelegates);
  const total = delegateIds.length;
  const isDone = n === total;

  const CaretIcon = ({ id, ...props }) =>
    !isComplete(current[id]) ? (
      <CrossCircledIcon {...props} />
    ) : openId === id ? (
      <CaretUpIcon {...props} />
    ) : (
      <CaretDownIcon {...props} />
    );

  return (
    <div>
      <div className="flex flex-col">
        {delegateIds.map((id, i) => {
          const name = idToName(id);

          const color = isComplete(current[id])
            ? colorFn(current[id])
            : "bg-amber-50";
          return (
            <div key={i} className="flex flex-col">
              <div
                className={`flex flex-row gap-2 ${color} cursor-pointer items-center justify-between p-2 sm:p-4`}
                onClick={() => setOpenId(openId === id ? null : id)}
              >
                <span className="flex gap-2 leading-none underline">
                  <CaretIcon id={id} className="h-4 w-4" />
                  {name}
                </span>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelect(id);
                  }}
                  className="!p-1 !text-sm sm:!px-2"
                >
                  {buttonText}
                </Button>
              </div>
              {id === openId && isComplete(current[id]) && (
                <div className="p-4">
                  <Content userId={id} {...current[id]} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      {isDone && (
        <div className="text-right">
          <ConfirmButton onClick={() => dispatch(confirmStageCompletion())} />
        </div>
      )}
    </div>
  );
}

function ConfirmButton({ onClick }) {
  return (
    <div className="mt-4 p-2">
      <Button
        variant="cta"
        className="flex flex-row items-center gap-1"
        onClick={onClick}
      >
        Next
      </Button>
    </div>
  );
}
