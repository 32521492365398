import React, { useMemo, useState } from "react";

import { idToName } from "../utils";
import { H2 } from "./H2";
import { Button } from "./Button";
import { Link } from "./Link";
import {
  CheckCircledIcon,
  CrossCircledIcon,
  Pencil1Icon,
} from "@radix-ui/react-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addDelegateData,
  currentStageData,
  setSubRoute,
} from "../slices/trainer";
import { CacheImage } from "./CacheImage";

export function PaperworkList({ delegate, allItems, receivedItems, onSelect }) {
  const [openItem, setOpenItem] = useState(null);
  const groups = useMemo(() => {
    if (!allItems || !receivedItems) return [];

    const asMap = allItems.reduce((obj, item) => {
      const [userId, type] = item.split("___");
      const [name] = type.split("_");

      if (userId !== delegate) return obj;

      const prev = obj[name] || {
        name,
        total: 0,
        complete: 0,
      };

      return {
        ...obj,
        [name]: {
          ...prev,
          id: item,
          total: prev.total + 1,
          complete: receivedItems.includes(item)
            ? prev.complete + 1
            : prev.complete,
        },
      };
    }, {});

    return Object.keys(asMap).map((k) => asMap[k]);
  }, [allItems, receivedItems, delegate]);

  return (
    <div className="mt-4 flex flex-col space-y-4">
      <H2 className="capitalize">{idToName(delegate)?.toLowerCase()}</H2>
      <div className="flex flex-col space-y-0.5 bg-slate-50">
        {groups.map((item, i) => {
          const isOpen = openItem === item.id;
          return (
            <PaperworkGroup
              {...item}
              key={i}
              onSelect={onSelect}
              isOpen={isOpen}
              onOpenChange={() => setOpenItem(isOpen ? null : item.id)}
            />
          );
        })}
      </div>
    </div>
  );
}

function PaperworkGroup({
  name,
  id,
  total,
  complete,
  onSelect,
  isOpen,
  onOpenChange,
}) {
  const isComplete = total === complete;
  const apos = total === 1 ? "" : "'s";

  return (
    <>
      <div className="flex flex-row justify-between bg-white px-2 py-4">
        <span className="flex flex-row justify-start gap-x-2 capitalize">
          {isComplete ? (
            <CheckCircledIcon className="h-6 w-6 text-green-600" />
          ) : (
            <CrossCircledIcon className="h-6 w-6 text-red-600" />
          )}{" "}
          {name} ({complete} / {total})
        </span>
        {isComplete ? (
          <Link
            onClick={onOpenChange}
            className="flex flex-row items-center gap-x-2"
          >
            <Pencil1Icon /> Edit Photos
          </Link>
        ) : (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onSelect(id);
            }}
            className="!p-1 !text-sm sm:!px-2"
          >
            Take Photo{apos}
          </Button>
        )}
      </div>
      {isOpen && <PaperworkItems id={id} />}
    </>
  );
}

function PaperworkItems({ id }) {
  const stage = useSelector(currentStageData);
  const dispatch = useDispatch();
  const [userId, item] = id.split("___");
  const [type] = item.split("_");

  const photos = useMemo(() => {
    const delStage = stage[userId];

    if (!delStage) return null;
    return Object.keys(delStage)
      .filter((k) => k.startsWith(type))
      .map((k) => delStage[k]);
  }, [userId, item, type, stage]);

  if (!photos) {
    return "No photos found";
  }

  return (
    <div className="flex-row justify-center gap-x-4 p-4">
      {photos.map((src, i) => {
        return (
          <div className="relative" key={i}>
            <div className="absolute left-0 top-0 flex w-full flex-row justify-between bg-black/70 p-2 text-white">
              Page {i + 1}
              <Button
                className="!p-1 !text-sm sm:!px-2"
                onClick={() => {
                  dispatch(
                    addDelegateData({
                      data: {
                        [item]: null,
                      },
                      userId,
                      doMerge: true,
                    }),
                  );
                  dispatch(setSubRoute(`${userId}___${type}_${i + 1}`));
                }}
              >
                Change Photo
              </Button>
            </div>
            <CacheImage imageKey={src} />
          </div>
        );
      })}
    </div>
  );
}
