import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { PageDelegateLogin } from "./PageDelegateLogin";
import { PageLatestStage } from "./PageLatestStage";
import { PageVortexRegistration } from "./PageVortexRegistration";
import { PageTheory } from "./PageTheory";
import { PageFeedback } from "./PageFeedback";
import { currentStage, shouldShowTransition } from "../slices/delegate";
import { CourseComplete } from "./CourseComplete";
import { TransitionGroup } from "./TransitionGroup";
import { PageStageTransition } from "./PageStageTransition";

const pages = [
  PageDelegateLogin,
  PageLatestStage,
  PageStageTransition,
  PageVortexRegistration,
  PageTheory,
  PageFeedback,
  CourseComplete,
];

export function RoutesDelegate() {
  const stages = useSelector((state) => state.delegate.stages);
  const stage = useSelector(currentStage);
  const showTransition = useSelector(shouldShowTransition);
  const showLatestStage = useSelector(
    (state) => state.delegate.showLatestStage,
  );

  const pageIndex = useMemo(() => {
    if (showLatestStage) return 1;
    if (showTransition) return 2;
    if (stages?.length) return stage ? getCourseComponentIndex(stage.Type) : 6;
    else return 0;
  }, [stages, showLatestStage, stage, showTransition]);

  return <TransitionGroup pageIndex={pageIndex} pages={pages} />;
}

function getCourseComponentIndex(name) {
  return {
    VORTEX_REGISTRATION: 3,
    THEORY: 4,
    FEEDBACK: 5,
  }[name];
}
