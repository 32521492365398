import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { H3 } from "./H3";
import { P } from "./P";
import { TrainerDelegateList } from "./TrainerDelegateList";
import {
  addDelegateData,
  setSubRoute,
  subRoute,
  currentStageData,
  currentStage,
} from "../slices/trainer";
import { WebcamVideo } from "./WebcamVideo";
import { CacheImage } from "./CacheImage";
import { CameraList } from "./CameraList";
import { PageLayout } from "./PageLayout";

export function PageCandidatePhotos() {
  const dispatch = useDispatch();
  const currentUserId = useSelector(subRoute);
  const currentData = useSelector(currentStageData);
  const stage = useSelector(currentStage);
  const delegates = useSelector((state) => state.trainer.delegates);
  const completedIds = useMemo(() => {
    return Object.keys(currentData).filter((k) => currentData[k]?.image);
  }, [currentData]);

  const onComplete = useCallback(
    (image) => {
      dispatch(addDelegateData({ userId: currentUserId, data: { image } }));
      if (!image) return;

      const currentIndex = delegates.indexOf(currentUserId);
      const orderedItems = [
        ...delegates.slice(currentIndex),
        ...delegates.slice(0, currentIndex),
      ];
      const [nextId] = orderedItems.filter(
        (id) => !currentData[id]?.image && id !== currentUserId,
      );

      if (!nextId) return dispatch(setSubRoute(null));
      dispatch(setSubRoute(nextId));
    },
    [
      currentUserId,
      delegates,
      dispatch,
      addDelegateData,
      setSubRoute,
      currentData,
    ],
  );

  return (
    <PageLayout>
      <div className="flex flex-col space-y-4">
        <H3>Candidate Photos</H3>
        <P>
          Find a suitable spot to take photos of the candidates. Ideally, a well
          lit spot with a white background.
        </P>
        <P>Try to capture the candidates photo within the oval shape.</P>
        <P>
          When the candidate is ready, hit the &quot;Take Photo&quot; next to
          their name and take the picture.
        </P>
        <TrainerDelegateList
          content={Content}
          isComplete={(data) => !!data?.image}
          onSelect={(id) => dispatch(setSubRoute(id))}
          buttonText="Take Photo"
        />
      </div>
      {!!currentUserId && stage?.Type === "CANDIDATE_PHOTOS" && (
        <WebcamVideo
          mask="face"
          onPhoto={onComplete}
          photo={currentData[currentUserId]?.image}
        >
          <CameraList
            items={delegates}
            completeItems={completedIds}
            currentItem={currentUserId}
          />
        </WebcamVideo>
      )}
    </PageLayout>
  );
}

function Content({ image }) {
  return <CacheImage imageKey={image} />;
}
