import React from "react";

export function MaskQR({ width, height, isMask, ...props }) {
  const smallest = Math.min(width, height);
  const size = smallest * 0.75;
  const miniSize = size * 0.15;
  const x = (width - size) / 2;
  const y = (height - size) / 2;
  const miniMargin = miniSize * 0.25;
  const miniMiniSize = miniSize * 0.5;
  const miniMiniMargin = miniSize * 0.25;

  return (
    <>
      <rect
        x={x}
        y={y}
        rx="10"
        ry="10"
        width={size}
        height={size}
        fill="black"
        {...props}
      />
      <rect
        x={x + miniMargin}
        y={y + miniMargin}
        rx="4"
        ry="4"
        width={miniSize}
        height={miniSize}
        fill="black"
        {...props}
      />
      <rect
        x={x + miniMargin + miniMiniMargin}
        y={y + miniMargin + miniMiniMargin}
        width={miniMiniSize}
        height={miniMiniSize}
        fill="black"
        {...props}
      />
      <rect
        x={x + size - miniSize - miniMargin}
        y={y + miniMargin}
        rx="4"
        ry="4"
        width={miniSize}
        height={miniSize}
        fill="black"
        {...props}
      />
      <rect
        x={x + size - miniSize - miniMargin + miniMiniMargin}
        y={y + miniMargin + miniMiniMargin}
        width={miniMiniSize}
        height={miniMiniSize}
        fill="black"
        {...props}
      />
      <rect
        x={x + miniMargin}
        y={y + size - miniSize - miniMargin}
        rx="4"
        ry="4"
        width={miniSize}
        height={miniSize}
        fill="black"
        {...props}
      />
      <rect
        x={x + miniMargin + miniMiniMargin}
        y={y + size - miniSize - miniMargin + miniMiniMargin}
        width={miniMiniSize}
        height={miniMiniSize}
        fill="black"
        {...props}
      />
    </>
  );
}
