import React from "react";
import * as Switch from "@radix-ui/react-switch";

export function PassFailSwitch({ onChange, value, label }) {
  return (
    <div className="flex items-center justify-between gap-2">
      <label className="mr-2">{label}</label>
      <Switch.Root
        checked={value}
        onCheckedChange={onChange}
        className={`relative inline-block h-8 w-16 select-none rounded-full align-middle transition duration-150 ease-in ${
          value ? "bg-green-600" : "bg-red-600"
        }`}
      >
        <Switch.Thumb className="absolute left-1 top-1 h-6 w-6 rounded-full border-2 border-gray-200 bg-white transition-[left] duration-150 ease-in data-[state=checked]:left-9" />
      </Switch.Root>
    </div>
  );
}
