import { nanoid } from "@reduxjs/toolkit";
import { currentStage, addDelegateData } from "../slices/trainer";
import { imageCached } from "../slices/api";

export const imageCache = (store) => (next) => async (action) => {
  const state = store.getState();
  const stage = currentStage(state);
  if (action.type === addDelegateData().type && !!action.payload.data) {
    const d = action.payload.data;
    const imageDataKeys = Object.keys(d).filter((k) => {
      const v = d[k];
      return typeof v === "string" && v.startsWith("data:image");
    });

    imageDataKeys.forEach(async (k) => {
      const v = d[k];
      const imageData = v.slice(23);

      const nid = nanoid();
      const uuid = `${stage.Type}___${nid}.jpeg`;
      action.payload.data[k] = uuid;

      const cache = await caches.open("imageCache");
      const arrayBuffer = base64ToUint8Array(imageData);
      const blob = new Blob([arrayBuffer], { type: "image/jpeg" });
      const response = new Response(blob);

      await cache.put(uuid, response);
      store.dispatch(imageCached(uuid));
    });
  }

  return next(action);
};

function base64ToUint8Array(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
