import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLiveCourses = createAsyncThunk(
  "api/getLiveCourses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch("/api/live-courses", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("live_access_token")}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const postLiveCourse = createAsyncThunk(
  "api/postLiveCourse",
  async ({ courseId, data }, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/live-courses/${courseId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("live_access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) throw new Error("Network response was not ok");

      return courseId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const putFeedback = createAsyncThunk(
  "api/putFeedback",
  async ({ delegateId, courseId, data }, { rejectWithValue }) => {
    console.log("firing", { delegateId, courseId, data });
    try {
      const response = await fetch(`/api/feedback/${delegateId}/${courseId}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });
      if (!response.ok) throw new Error("Network response was not ok");

      return { delegateId, courseId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const putDump = createAsyncThunk("api/dump", async () => {
  const data = {};
  for (var i = 0, len = localStorage.length; i < len; ++i) {
    const key = localStorage.key(i);
    const val = localStorage.getItem(key);
    data[key] = val;
  }

  const response = await fetch(`/api/dump`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("live_access_token")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response;
});

export const uploadImage = createAsyncThunk(
  "api/uploadImage",

  async (key, { rejectWithValue }) => {
    try {
      const cache = await caches.open("imageCache");
      const matched = await cache.match(key);

      const blob = await matched.blob();
      const [type, uuid] = key.split("___");
      const base64 = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });

      const response = await fetch(`/api/media/${type}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("live_access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: uuid, file: base64 }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        cache.delete(key);
      }

      return key;
    } catch (error) {
      console.error(error.message);
      return rejectWithValue(key);
    }
  },
);
