import { install } from "resize-observer";

export default function () {
  resizeObserverPolyfill();
  replaceAllPolyfill();
}

function resizeObserverPolyfill() {
  if (!window.ResizeObserver) install();
}

function replaceAllPolyfill() {
  if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (str, newStr) {
      // If a regex pattern
      if (
        Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
      ) {
        return this.replace(str, newStr);
      }

      // If a string
      return this.replace(new RegExp(str, "g"), newStr);
    };
  }
}
