export function idToName(id) {
  if (!id) return null;
  const [firstName, surname] = id.split("_");

  return surname ? `${firstName} ${surname}` : firstName;
}

export function markTheory(stage, data) {
  const { GivenAnswers } = data;
  const { TotalToPass, CourseQuestions } = stage.Details;
  const filteredQuestions = CourseQuestions.filter(
    (q) => q.Type === "CheckBox" || q.Type === "MultipleChoice",
  );
  const totalRequired =
    TotalToPass - (CourseQuestions.length - filteredQuestions.length);
  const totalMarks = filteredQuestions.reduce((n, item, i) => {
    if (!item.Answers?.length) return n;

    const givenAnswer = GivenAnswers[i];
    if (typeof givenAnswer !== "number") return n;

    return n + (item.Answers[givenAnswer]?.Points || 0);
  }, 0);

  const pass = totalMarks >= totalRequired;

  return {
    pass,
    totalMarks,
  };
}

export function markPractical(stage, data) {
  const { GivenAnswers } = data;
  const { TotalToPass, CourseQuestions } = stage.Details;
  const filteredQuestions = CourseQuestions.filter(
    (q) => q.Type === "CheckBox" || q.Type === "MultipleChoice",
  );
  const totalRequired =
    TotalToPass - (CourseQuestions.length - filteredQuestions.length);
  const totalMarks = filteredQuestions.reduce((n, item, i) => {
    if (!item.Answers?.length) return n;

    const givenAnswer = GivenAnswers[i];
    if (typeof givenAnswer !== "number") return n;

    return n + item.Answers[givenAnswer].Points;
  }, 0);

  const pass = totalMarks >= totalRequired;

  return {
    pass,
    totalMarks,
  };
}
