import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { delegateLiveCourse, setSubRoute } from "../slices/trainer";
import { QRCode } from "./QRCode";
import { Button } from "./Button";
import { H2 } from "./H2";
import { P } from "./P";
import { PageLayout } from "./PageLayout";
import { DashboardIcon } from "@radix-ui/react-icons";

export function PageGenericSignIn() {
  const dispatch = useDispatch();
  const course = useSelector(delegateLiveCourse);

  return (
    <PageLayout>
      <H2>Course Registration</H2>
      <P>
        Ask each candidate to hit &quot;Get Started&quot; on their tablet and
        scan the QR code below.
      </P>
      <QRCode data={course} />
      <div className="text-right">
        <Button
          onClick={() => dispatch(setSubRoute("SCAN_REGISTER"))}
          variant="inconspic"
        >
          <DashboardIcon /> Candidates have ALL scanned
        </Button>
      </div>
    </PageLayout>
  );
}
