import React, { forwardRef } from "react";
import { Label } from "./Label";
import { FormInfo } from "./FormInfo";
import { Loading } from "./Loading";
import { ErrorText } from "./ErrorText";

function InputComponent(
  {
    required,
    label,
    name,
    info,
    icon,
    isLoading,
    className = "",
    errorText,
    transform,
    onChange,
    ...inputProps
  },
  ref,
) {
  const colouredClassName = errorText
    ? className + " border-red-600"
    : className;

  return (
    <div className="flex flex-col gap-1">
      <Label htmlFor={name} required={required || inputProps.required}>
        {label}
      </Label>
      <div className="relative">
        <input
          name={name}
          ref={ref}
          onChange={(e) => {
            const fn = transformers[transform] || ((str) => str);
            e.target.value = fn(e.target.value);
            onChange(e);
          }}
          required={required}
          className={`block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pr-2 !leading-none text-gray-900 focus:outline-amber-600 sm:text-sm ${colouredClassName}`}
          {...inputProps}
        />
        <div className="pointer-events-none absolute right-2.5 top-1/2 -translate-y-1/2">
          {isLoading && <Loading size={6} />}
        </div>
      </div>
      {!!info && <FormInfo>{info}</FormInfo>}
      {!!errorText && (
        <ErrorText className="!mt-0 !py-0 text-sm">{errorText}</ErrorText>
      )}
    </div>
  );
}

const transformers = { uppercase };

function uppercase(str) {
  return str.toUpperCase();
}

export const Input = forwardRef(InputComponent);
