import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ ...this.state, error, info });
  }

  render() {
    if (this.state.hasError) {
      const C = this.props.fallback;
      return <C error={this.state.error} info={this.state.info} />;
    }

    return this.props.children;
  }
}
