import React from "react";

import VortexBlue from "../Images/vortex-blue.png";
import VortexWhite from "../Images/vortex-white.png";

const imageByVariant = {
  blue: VortexBlue,
  white: VortexWhite,
};

export function Loading({ size = 8, variant = "blue" }) {
  return (
    <img
      src={imageByVariant[variant]}
      className={`w-${size} h-${size} inline-block animate-spin`}
      alt="Loading"
    />
  );
}
