import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { PageScanRegister as SCAN_REGISTER } from "./PageScanRegister";
import { PageGenericSignIn as GENERIC_SIGN_IN } from "./PageGenericSignIn";
import { PageSpecificSignIn as SPECIFIC_SIGN_IN } from "./PageSpecificSignIn";
import { subRoute, createPin } from "../slices/trainer";
import { TransitionGroup } from "./TransitionGroup";

const pages = [GENERIC_SIGN_IN, SPECIFIC_SIGN_IN, SCAN_REGISTER];

export function PageTrainerRegistration() {
  const dispatch = useDispatch();
  const ensurePin = () => dispatch(createPin());

  const sub = useSelector(subRoute);
  const pageIndex = useMemo(() => {
    const arr = ["GENERIC_SIGN_IN", "SPECIFIC_SIGN_IN", "SCAN_REGISTER"];
    return arr.indexOf(sub || "GENERIC_SIGN_IN");
  }, [sub]);

  useEffect(() => {
    ensurePin();
  }, [ensurePin]);

  return <TransitionGroup pages={pages} pageIndex={pageIndex} />;
}
