import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckIcon, Cross1Icon, Pencil1Icon } from "@radix-ui/react-icons";

import { TrainerDelegateList } from "./TrainerDelegateList";
import { QRReader } from "./QRReader";
import { H3 } from "./H3";
import { P } from "./P";
import { Link } from "./Link";
import {
  addDelegateData,
  currentStage,
  totalDelegates,
  nDelegates,
  edit,
  currentStageData,
} from "../slices/trainer";
import { PageLayout } from "./PageLayout";
import { CameraList } from "./CameraList";

export function PageTrainerTheory() {
  const dispatch = useDispatch();
  const [readerIsOpen, setReaderIsOpen] = useState(false);
  const total = useSelector(totalDelegates);
  const n = useSelector(nDelegates);
  const delegates = useSelector((state) => state.trainer.delegates);
  const stage = useSelector(currentStage);
  const current = useSelector(currentStageData);
  const pin = useSelector((state) => state.trainer.pin);

  const completed = Object.keys(current);

  const validate = useCallback((data) => {
    console.log(data);
    return (
      typeof data?.userId === "string" &&
      Array.isArray(data?.data?.GivenAnswers)
    );
  }, []);

  return (
    <PageLayout>
      <div className="flex flex-col space-y-4">
        <H3>Theory</H3>
        <P>
          Ask each of the candidates to complete the theory questions on their
          device.
        </P>
        <P>Once they are done, scan each users barcode to get results.</P>
        <P>They will need this PIN number to see the questions:</P>
        <p className="text-center text-4xl tracking-widest text-slate-900">
          {pin}
        </p>
        <TrainerDelegateList
          content={Content}
          colorFn={({ pass }) => (pass ? "bg-emerald-50" : "bg-red-50")}
          onSelect={() => setReaderIsOpen(true)}
          buttonText="Scan Barcode"
          isComplete={(data) => !!data}
        />
      </div>
      {readerIsOpen && n < total && stage?.Type === "THEORY" && (
        <QRReader
          close={() => setReaderIsOpen(false)}
          onComplete={(data) => dispatch(addDelegateData(data))}
          validateQR={validate}
        >
          <CameraList items={delegates} completeItems={completed} />
        </QRReader>
      )}
    </PageLayout>
  );
}

function Content({ pass, totalMarks, userId }) {
  const dispatch = useDispatch();
  const stageInfo = useSelector(currentStage);
  const onEditClick = () => dispatch(edit({ userId }));
  const { CourseQuestions } = stageInfo.Details;
  const filteredQuestions = CourseQuestions.filter(
    (q) => q.Type === "CheckBox" || q.Type === "MultipleChoice",
  );

  const Icon = pass ? CheckIcon : Cross1Icon;
  const color = pass ? "green" : "red";
  const className = `flex items-center gap-1 font-bold text-${color}-700`;

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      <span className="flex justify-start gap-2">
        <span className={className}>
          <Icon className="h-6 w-6" />
          {pass ? "PASSED" : "FAILED"}
        </span>
        {totalMarks} / {filteredQuestions.length}
      </span>
      <Link onClick={onEditClick} className="flex flex-row items-center gap-2">
        <Pencil1Icon className="h-6 w-6" /> Edit
      </Link>
    </div>
  );
}
