import React from "react";

export function Label({ htmlFor, children, required }) {
  if (!children) return null;

  return (
    <label
      htmlFor={htmlFor}
      className="block text-sm font-medium text-gray-900"
    >
      {children}
      {required && <span className="pl-1 text-red-600">*</span>}
    </label>
  );
}
