import React from "react";
import * as CheckboxLib from "@radix-ui/react-checkbox";
import { CheckIcon } from "@radix-ui/react-icons";
import { useController } from "react-hook-form";

function CheckBoxComponent({
  isChecked,
  checkedClassName = "bg-yellow-50",
  onCheckedChange,
  label,
  id,
}) {
  const bgColor = isChecked ? checkedClassName : "bg-slate-50";
  return (
    <div className={`mt-2 flex items-center gap-x-2 px-2 ${bgColor}`}>
      <CheckboxLib.Root
        checked={isChecked}
        onCheckedChange={onCheckedChange}
        id={id}
        className={`mt-1 h-5 w-5 shrink-0 basis-5 overflow-hidden rounded-sm border border-slate-200 bg-slate-100`}
      >
        <CheckboxLib.Indicator className="flex items-center justify-center text-slate-800">
          <CheckIcon />
        </CheckboxLib.Indicator>
      </CheckboxLib.Root>
      <label
        className="grow cursor-pointer py-2 text-sm leading-snug text-slate-800"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
}

function FormField({ control, formKey, rules = {}, ...checkboxProps }) {
  const { field } = useController({ control, name: formKey, rules });
  return (
    <CheckBox
      isChecked={field.value || false}
      onCheckedChange={field.onChange}
      {...checkboxProps}
    />
  );
}

export const CheckBox = Object.assign(CheckBoxComponent, {
  FormField,
});
