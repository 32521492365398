import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCourse } from "../slices/trainer";
import { courses } from "../slices/api";
import { getLiveCourses } from "../thunks/api";
import { H3 } from "./H3";
import { parse, format } from "date-fns";
import { H2 } from "./H2";
import { Loading } from "./Loading";
import { P } from "./P";
import { PageLayout } from "./PageLayout";

export function PageCourseList() {
  const isLoading = useSelector((state) => state.api?.isLoading);
  const data = useSelector(courses);
  const dispatch = useDispatch();
  const onClick = useCallback(
    (item) => {
      return () => dispatch(selectCourse(item));
    },
    [dispatch, selectCourse],
  );

  useEffect(() => {
    dispatch(getLiveCourses());
  }, [getLiveCourses]);

  const { todaysCourses, liveCourses } = useMemo(() => {
    if (!Array.isArray(data)) return [];
    const now = format(new Date(), "yyyy-MM-dd");
    const lists = data.filter(Boolean).reduce(
      (obj, item) => {
        const isToday = !!item.Blocks.find((b) => b === now);
        const k = isToday ? "todaysCourses" : "liveCourses";

        return {
          ...obj,
          [k]: [...obj[k], item],
        };
      },
      {
        todaysCourses: [],
        liveCourses: [],
      },
    );

    return {
      ...lists,
      liveCourses: lists.liveCourses.sort((a, b) => {
        return a.Blocks[0] > b.Blocks[0] ? 1 : -1;
      }),
    };
  }, [data]);

  return (
    <PageLayout>
      {todaysCourses?.length || liveCourses.length ? (
        <div className="flex flex-col">
          {todaysCourses.length ? (
            <>
              <H2 className="pb-4 text-slate-800">Today</H2>
              {todaysCourses.map((course, i) => {
                return <Course key={i} item={course} onClick={onClick} />;
              })}
            </>
          ) : null}
          <H2 className="py-4 text-slate-600">Coming Up</H2>
          {liveCourses.map((item, i) => {
            return <Course key={i} item={item} />;
          })}
        </div>
      ) : isLoading ? (
        <div className="flex justify-center">
          <Loading />
        </div>
      ) : (
        <div>
          <H2>No courses</H2>
          <P>You are not registered to take part in any courses.</P>
        </div>
      )}
    </PageLayout>
  );
}

function Course({ item, onClick }) {
  const date = format(
    parse(item.Blocks[0], "yyyy-MM-dd", new Date()),
    "iiii, MMMM do, yyyy",
  );

  return (
    <div
      onClick={onClick ? onClick(item) : () => {}}
      style={onClick ? {} : { opacity: 0.4 }}
      className="cursor-pointer p-4 shadow-sm hover:bg-blue-50"
    >
      <header className="flex flex-row items-start justify-start gap-x-4">
        <span className="rounded-md bg-blue-900 p-2 text-sm uppercase leading-none text-white">
          {item.Course.AwardingBody}
        </span>
        <H3 className="!-mt-1 leading-snug">{item.Course.Name}</H3>
      </header>
      <span className="block pt-2 text-slate-600">{date}</span>
      <div className="mt-2 flex flex-col gap-1">
        <Row name="Location">{item.SiteLocation}</Row>
        <Row name="Site Contact">{item.SiteContact}</Row>
        <Row name="Testing Type">{item.TestingType}</Row>
        <Row name="Company">{item.Organisations?.join(", ")}</Row>
        <Row name="Reference Number">{item.VortexRef}</Row>
        <Row name="Number of Delegates">{item.TotalDelegates}</Row>
      </div>
    </div>
  );
}

function Row({ name, children }) {
  return children ? (
    <span className="text-sm text-slate-500">
      <strong>{name}: </strong>
      {children}
    </span>
  ) : null;
}
