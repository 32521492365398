import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addDelegateData,
  currentStageData,
  confirmStageCompletion,
} from "../slices/trainer";
import { PassFailSwitch } from "./PassFailSwitch";
import { idToName } from "../utils";
import { H3 } from "./H3";
import { P } from "./P";
import { Button } from "./Button";
import { PageLayout } from "./PageLayout";

export function PageTrainerResults() {
  const dispatch = useDispatch();
  const delegates = useSelector((state) => state.trainer.delegates);
  const data = useSelector(currentStageData);

  useEffect(() => {
    delegates.forEach((delegate) => {
      dispatch(
        addDelegateData({
          userId: delegate,
          data: { theory: true, practical: true },
        }),
      );
    });
  }, [delegates, dispatch]);

  const handleCheckboxChange = (delegateId, type, value) => {
    dispatch(
      addDelegateData({
        userId: delegateId,
        data: { [type]: value },
        doMerge: true,
      }),
    );
  };

  return (
    <PageLayout>
      <div className="flex flex-col space-y-6">
        <H3>Course Results</H3>
        <P>
          For each of the delegates below, select whether they have passed or
          failed.
        </P>
        <div className="flex flex-row">
          {delegates.map((delegate) => (
            <div
              key={delegate}
              className={`flex basis-48 flex-col space-y-4 p-4 shadow-md transition duration-150 ${bgColor(
                data[delegate],
              )}`}
            >
              <H3 className="!mt-0 font-bold text-slate-800">
                {idToName(delegate)}
              </H3>
              <PassFailSwitch
                label="Theory"
                value={data[delegate]?.theory}
                onChange={(value) =>
                  handleCheckboxChange(delegate, "theory", value)
                }
              />
              <PassFailSwitch
                label="Practical"
                value={data[delegate]?.practical}
                onChange={(value) =>
                  handleCheckboxChange(delegate, "practical", value)
                }
              />
            </div>
          ))}
        </div>
        <div className="text-right">
          <Button
            variant="cta"
            onClick={() => dispatch(confirmStageCompletion())}
          >
            Next
          </Button>
        </div>
      </div>
    </PageLayout>
  );
}

function bgColor(delegateData = {}) {
  const passingAll = Object.keys(delegateData).reduce(
    (b, k) => (b ? !!delegateData[k] : b),
    true,
  );

  return passingAll ? "bg-green-50" : "bg-red-50";
}
