import { setStageData } from "../slices/delegate";
import { addDelegateData, edit } from "../slices/trainer";

const TRAINER_TRIGGER_TYPES = [addDelegateData().type, edit().type];
const DELEGATE_TRIGGER_TYPES = [setStageData().type];

export const backupMiddleware = (store) => (next) => (action) => {
  next(action);

  try {
    const isTrainerTriggerAction = TRAINER_TRIGGER_TYPES.includes(action.type);
    const isDelegateTriggerAction = DELEGATE_TRIGGER_TYPES.includes(
      action.type,
    );

    if (isTrainerTriggerAction) {
      saveTrainerBackup(action, store.getState().trainer?.course?._id);
    } else if (isDelegateTriggerAction) {
      saveDelegateBackup(action);
    }
  } catch (e) {
    console.error("Backup failed", e);
  }
};

function saveDelegateBackup(action) {
  const stored = window.localStorage.getItem("dbackup");
  const arr = stored ? JSON.parse(stored) : [];
  const carryOverItems = arr.length >= 10 ? arr.slice(1) : arr;

  const toSave = JSON.stringify([
    ...carryOverItems,
    {
      action,
    },
  ]);

  window.localStorage.setItem("dbackup", toSave);
}

function saveTrainerBackup(action, courseId) {
  const stored = window.localStorage.getItem("tbackup");
  const arr = stored ? JSON.parse(stored) : [];
  const carryOverItems = arr.length >= 30 ? arr.slice(1) : arr;

  const toSave = JSON.stringify([
    ...carryOverItems,
    {
      action,
      courseId,
    },
  ]);

  window.localStorage.setItem("tbackup", toSave);
}
