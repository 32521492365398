import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { setStageData, toggleShowLatestStage } from "../slices/delegate";
import { Button } from "./Button";
import { PageLayout } from "./PageLayout";
import { queueFeedback } from "../slices/api";

const QUESTIONS = [
  "How would you rate your trainer?",
  "How was the presentation of the course?",
  "Did the course meet your needs?",
  "How was the duration of the course?",
  "Will you be able to apply the knowledge learned today?",
  "Would you recommend this course to others?",
  "Would you recommend our company to others?",
  "COMMENTS",
];

export function PageFeedback() {
  const dispatch = useDispatch();
  const courseId = useSelector(
    (state) => state.delegate.stages[0]?.Details?.courseId,
  );
  const delegateId = useSelector((state) => state.delegate.userId);
  const [values, setValues] = useState([]);
  const commentsRef = useRef(null);

  const transitions = useTransition(values.length, {
    initial: { opacity: 0, transform: "translate3d(0,-100%,0)" },
    from: { opacity: 0, transform: "translate3d(0,-100%,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0%,0)" },
    leave: { opacity: 0, transform: "translate3d(0,100%,0)" },
  });

  const onComplete = useCallback(() => {
    if (!commentsRef.current) return;

    const value = commentsRef.current.value || "";
    const toSubmit = [...values, value];
    dispatch(setStageData(toSubmit));
    dispatch(toggleShowLatestStage());
    dispatch(
      queueFeedback({
        courseId,
        delegateId,
        data: toSubmit,
      }),
    );
  }, [commentsRef.current, values]);

  return (
    <PageLayout>
      <div className="relative flex h-full w-full flex-col overflow-y-hidden">
        {transitions((style, i) => {
          return (
            <FeedbackItem
              onSelect={(value) => setValues([...values, value])}
              n={i}
              isActive={i === values.length}
              commentsRef={commentsRef}
              key={i}
              style={style}
            >
              {QUESTIONS[i]}
            </FeedbackItem>
          );
        })}
      </div>
      {values.length === QUESTIONS.length - 1 && (
        <div className="absolute bottom-0 z-[9999999999] w-full p-8 text-right">
          <Button variant="cta" onClick={onComplete}>
            Done
          </Button>
        </div>
      )}
    </PageLayout>
  );
}

function FeedbackItem({ children, onSelect, n, isActive, commentsRef, style }) {
  return (
    <animated.div
      className="absolute left-0 top-0 flex h-full w-full flex-col space-y-16 px-8 py-16"
      style={style}
    >
      <h2 className="text-center text-3xl !leading-normal text-slate-700 sm:text-6xl">
        {children === "COMMENTS" ? "Any other comments?" : children}
      </h2>
      <div className="flex flex-row justify-between">
        {children === "COMMENTS" ? (
          <textarea
            ref={commentsRef}
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-2xl text-slate-900 focus:outline-amber-600"
            rows="3"
          />
        ) : (
          <>
            <FeedbackButton onClick={() => onSelect(-1)}>😞</FeedbackButton>
            <FeedbackButton onClick={() => onSelect(0)}>😐</FeedbackButton>
            <FeedbackButton onClick={() => onSelect(1)}>😃</FeedbackButton>
          </>
        )}
      </div>
    </animated.div>
  );
}

function FeedbackButton(buttonProps) {
  return (
    <div>
      <button
        className="rounded-lg border border-slate-100 bg-gradient-to-b from-white to-slate-100 p-4 text-4xl shadow-md sm:text-8xl"
        {...buttonProps}
      />
    </div>
  );
}
