import React from "react";
import { CheckBox } from "./CheckBox";
import { useController } from "react-hook-form";

export function QuestionCheckBox({ Answers, formKey }) {
  const {
    field: { onChange, value },
  } = useController({ name: formKey, rules: { required: true } });

  return (
    <div className="flex w-full flex-col">
      {Answers.map((answer, i) => {
        const checkedClassName =
          answer.Points === 0
            ? "bg-red-50"
            : answer.Points > 0
            ? "bg-green-50"
            : undefined;

        return (
          <CheckBox
            key={i}
            checkedClassName={checkedClassName}
            label={typeof answer.Answer === "string" ? answer.Answer : answer}
            id={`${formKey}_${i}`}
            onCheckedChange={(isChecked) => onChange(isChecked ? i : null)}
            isChecked={value === i}
          />
        );
      })}
    </div>
  );
}
