import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { WebcamVideo } from "./WebcamVideo";
import { addError } from "../slices/user";

export function QRReader({ onComplete, children, validateQR }) {
  const dispatch = useDispatch();
  const handleData = useCallback(
    (data) => {
      const isValid = validateQR(data);
      if (!isValid)
        return dispatch(addError({ text: "Incorrect QR code scanned.", data }));

      onComplete(data);
    },
    [validateQR, onComplete],
  );

  return (
    <WebcamVideo onComplete={handleData} readQRs={true} mask="qr">
      {children}
    </WebcamVideo>
  );
}
