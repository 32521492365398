import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../slices/shared";
import { imagesToUpload, completeCourse } from "../slices/api";

import { H2 } from "./H2";
import { P } from "./P";
import { Button } from "./Button";
import { Loading } from "./Loading";
import { PageLayout } from "./PageLayout";

export function CourseCompleteTrainer() {
  const dispatch = useDispatch();
  const lastUpdatedData = useSelector((state) => state.trainer.lastUpdated);
  const lastUpdatedAPI = useSelector((state) => state.api.lastUpdated);
  const remainingImages = useSelector(imagesToUpload);

  const complete = useCallback(() => {
    dispatch(completeCourse());
    dispatch(reset());
  }, [dispatch]);

  const isComplete =
    !remainingImages.length && lastUpdatedAPI > lastUpdatedData;

  return (
    <PageLayout>
      <div className="flex flex-col gap-4">
        <H2>Course Complete</H2>
        {isComplete ? (
          <>
            <P>
              Thank you for using Vortal Live today. Hit the &quot;Finish&quot;
              button to save everything.
            </P>
            <div className="text-right" onClick={complete}>
              <Button variant="cta">Finish</Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center gap-4">
              <Loading />
              <P>Synchronising...</P>
            </div>
            <P>
              Once complete, your work here is done. If this page is taking a
              while, try to get onto a better internet connection.
            </P>
          </>
        )}
      </div>
    </PageLayout>
  );
}
