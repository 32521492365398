import React, { useEffect, useState, useMemo } from "react";

import { useWorker } from "../hooks/useWorker";
import { useScreenSize } from "../hooks/useScreenSize";

export function QRCode({ data }) {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [qrs, send] = useWorker();
  const { width, height } = useScreenSize();
  const size = useMemo(() => {
    if (!width || !height) return 0;

    return height > width ? width * 0.8 - 32 : height / 2;
  }, [width, height]);

  useEffect(() => {
    if (!send || !data || !size) return;

    send("generateQRs", { data, size });
  }, [send, data, size]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [qrs, setCurrentIndex]);

  useEffect(() => {
    if (currentIndex === -1) return;
    const timeout = setTimeout(() => {
      setCurrentIndex(currentIndex + 1);
    }, 200);

    return () => clearTimeout(timeout);
  }, [currentIndex, setCurrentIndex]);

  const currentQR = useMemo(() => {
    if (!qrs || currentIndex === -1) return;
    return qrs[currentIndex % qrs.length];
  }, [qrs, currentIndex]);

  if (!data) return null;

  return (
    <div className="relative flex w-full flex-row items-center justify-center py-8">
      {currentQR ? (
        <>
          <div className="relative mx-auto mt-4 inline-flex items-center">
            <div dangerouslySetInnerHTML={{ __html: currentQR }}></div>
          </div>
        </>
      ) : (
        <div style={{ width: size, height: size }} />
      )}
    </div>
  );
}
