import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
} from "react";
import { VideoError } from "./VideoError";
import { useScreenSize } from "../hooks/useScreenSize";

export function VideoFeed({
  containerRef,
  readQRs,
  onComplete,
  snapper,
  onActive,
}) {
  const [isDelayed, setIsDelayed] = useState(true);
  const workerRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const contextRef = useRef(null);
  const screenSize = useScreenSize();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [error, setError] = useState(false);
  // const actualPixelRatio = 1;
  const actualPixelRatio = readQRs ? 1 : window.devicePixelRatio;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDelayed(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const { width, height } = containerRef.current.getBoundingClientRect();
    setWidth(width);
    setHeight(height);
  }, [screenSize]);

  const onFrame = useCallback(() => {
    if (!workerRef.current) return;

    const imageData = getImageData(
      contextRef.current,
      canvasRef.current,
      videoRef.current,
    );
    if (!imageData) setTimeout(onFrame, 100);
    else {
      workerRef.current.postMessage({ cmd: "RUN", data: imageData });
    }
  }, []);

  const createWorker = useCallback(() => {
    cleanup();

    workerRef.current = new Worker(
      new URL("../workers/readQRs.js", import.meta.url),
    );
    workerRef.current.postMessage({ cmd: "INIT" });
    workerRef.current.onmessage = onWorkerMessage;
    workerRef.current.onerror = onWorkerError;
  }, []);

  const cleanup = useCallback(() => {
    if (workerRef.current) {
      workerRef.current.terminate();
      workerRef.current = null;
    }
  });

  const onWorkerMessage = useCallback((e) => {
    const { cmd, data } = e.data;

    if (cmd === "READY") {
      if (onActive) onActive(!!data);
      onFrame();
    } else if (cmd === "DONE") {
      onComplete(data);
      createWorker();
    }
  }, []);

  const onWorkerError = useCallback((e) => {
    console.error(e);
  });

  useEffect(() => {
    if (!readQRs) {
      cleanup();
      return;
    }

    createWorker();

    return cleanup;
  }, [readQRs]);

  const onVidFrame = useCallback(() => {
    const v = videoRef.current;
    const c = canvasRef.current;
    if (!containerRef.current) return;
    const { width, height } = containerRef.current.getBoundingClientRect();

    if (!c || !v) return;
    contextRef.current = c.getContext("2d", {
      willReadFrequently: true,
    });

    if (v.readyState === v.HAVE_ENOUGH_DATA) {
      const actuals = {
        width: v.videoWidth,
        height: v.videoHeight,
      };

      const idealHeight = height * actualPixelRatio;
      const idealWidth = width * actualPixelRatio;
      const ratio = Math.max(
        idealWidth / actuals.width,
        idealHeight / actuals.height,
        1,
      );

      const destWidth = idealWidth / ratio;
      const destHeight = idealHeight / ratio;

      const x = Math.max((actuals.width - destWidth) / 2, 0);
      const y = Math.max((actuals.height - destHeight) / 2, 0);

      contextRef.current.scale(actualPixelRatio, actualPixelRatio);
      v.width = actuals.width;
      v.height = actuals.height;
      c.width = destWidth;
      c.height = destHeight;
      c.style.width = `${destWidth / actualPixelRatio}px`;
      c.style.height = `${destHeight / actualPixelRatio}px`;

      contextRef.current.drawImage(
        v,
        x,
        y,
        destWidth,
        destHeight,
        0,
        0,
        destWidth,
        destHeight,
      );

      requestFrameCallback(videoRef.current, onVidFrame);
    }
  }, [actualPixelRatio]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (isDelayed) return;

    async function start() {
      let error = false;
      console.log(streamRef.current);
      try {
        if (!streamRef.current) {
          console.log("making new");
          streamRef.current = 1;
          const nextStream = await getStream(width, height, actualPixelRatio);
          streamRef.current = nextStream;
          resetConstraints();
        }
      } catch (e) {
        console.error(e);
        error = e.message;
      }

      setError(error);
      if (error) return;
      if (!videoRef.current) return;
      if (streamRef.current === 1) return;
      videoRef.current.srcObject = streamRef.current;
      const videoTrack = streamRef.current.getVideoTracks()[0];
      resetConstraints();

      // Listen for the 'ended' event
      videoTrack.onended = function () {
        setError("Stream ended.");
      };

      videoRef.current.onloadedmetadata = () => {
        if (!videoRef.current) return;
        videoRef.current.play();
        requestFrameCallback(videoRef.current, onVidFrame);
        if (onFrame) onFrame();
      };
    }

    function resetConstraints() {
      const videoTrack = streamRef.current.getVideoTracks()[0];
      setTimeout(() => {
        console.log("ITS STARTED");
        if (!containerRef.current) return;
        const { width, height } = containerRef.current.getBoundingClientRect();
        videoTrack
          .applyConstraints(
            getConstraints(width, height, actualPixelRatio, true),
          )
          .then(() => {
            console.log(streamRef.current.getVideoTracks()[0].getSettings());
          });
        console.log("applying constraints", width, height);
      }, 0);
    }

    async function stop() {
      if (streamRef.current && streamRef.current !== 1) {
        const tracks = streamRef.current.getVideoTracks();
        tracks.forEach((track) => track.stop());
        streamRef.current = null;
      }
    }

    start();

    return stop;
  }, [
    onFrame,
    width,
    height,
    onVidFrame,
    actualPixelRatio,
    setError,
    isDelayed,
  ]);

  const snap = useCallback(() => {
    if (!canvasRef.current) return;

    return {
      w: canvasRef.current.width,
      h: canvasRef.current.height,
      cssw: canvasRef.current.style.width,
      cssh: canvasRef.current.style.height,
      img: canvasRef.current.toDataURL("image/jpeg"),
    };
  }, []);

  return error ? (
    <VideoError error={error} />
  ) : (
    <div className="h-full w-full overflow-hidden text-center">
      <video
        playsInline={true}
        ref={videoRef}
        className="absolute left-0 top-0 z-20 hidden max-w-none"
      />
      <canvas
        ref={canvasRef}
        className="absolute left-1/2 top-1/2 z-30 max-w-none -translate-x-1/2 -translate-y-1/2 object-cover"
      />
      {!!snapper && snapper(snap)}
    </div>
  );
}

function requestFrameCallback(v, fn) {
  if (v.requestVideoFrameCallback) {
    v.requestVideoFrameCallback(fn);
  } else {
    requestAnimationFrame(fn);
  }
}

async function getStream(width, height, actualPixelRatio) {
  const constraints = getConstraints(width, height, actualPixelRatio);
  const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

  // Try to get a stream from the default camera
  let stream = await navigator.mediaDevices.getUserMedia(constraints);

  // If the device has more than one camera, try to get a stream from the next camera
  if (
    navigator.mediaDevices.enumerateDevices &&
    !supportedConstraints.facingMode
  ) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput",
    );

    if (videoDevices.length > 1) {
      constraints.video.deviceId = { exact: videoDevices[1].deviceId };
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    }
  }

  return stream;
}

function getConstraints(width, height, actualPixelRatio, isUpdate) {
  const constraints = {
    video: {
      height: height * actualPixelRatio,
      frameRate: 60,
    },
    audio: false,
  };

  const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
  if (supportedConstraints.facingMode) {
    constraints.video.facingMode = "environment";
  }

  return isUpdate ? constraints.video : constraints;
}

function getImageData(c2d, c, v) {
  if (c2d && v?.readyState === v?.HAVE_ENOUGH_DATA && !!c) {
    return [c2d.getImageData(0, 0, c.width, c.height).data, c.width, c.height];
  }
}
