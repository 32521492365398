import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { setStageData } from "../slices/delegate";
import { FormVortexRegistration } from "./FormVortexRegistration";
import { PageLayout } from "./PageLayout";
import { Terms } from "./Terms";

export function PageVortexRegistration() {
  const [formContents, setFormContents] = useState(null);
  const [termsCancelled, setTermsCancelled] = useState(false);
  const dispatch = useDispatch();

  const onComplete = useCallback(
    (TermsAgreedAt) => {
      dispatch(
        setStageData({
          ...formContents,
          TermsAgreedAt,
        }),
      );
    },
    [formContents],
  );

  const onRegSubmit = useCallback(
    (data) => {
      setFormContents(data);
      setTermsCancelled(false);
    },
    [setFormContents],
  );

  return (
    <PageLayout>
      <div className="flex flex-col gap-4">
        {formContents && !termsCancelled ? (
          <Terms
            onComplete={onComplete}
            onCancel={() => setTermsCancelled(true)}
          />
        ) : (
          <FormVortexRegistration
            onSubmit={onRegSubmit}
            defaultValues={formContents || undefined}
          />
        )}
      </div>
    </PageLayout>
  );
}
