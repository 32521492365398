import React from "react";

export function MaskPaper({ width, height, isMask, ...props }) {
  const paperWidth = height * 0.6 * 0.7;

  return (
    <rect
      x={(width - paperWidth) / 2}
      y="20%"
      rx="10"
      ry="10"
      width={paperWidth}
      height="60%"
      fill="black"
      {...props}
    />
  );
}
