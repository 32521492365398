import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { login, setUserType } from "../slices/user";
import { Input } from "./Input";
import { Button } from "./Button";
import { ErrorText } from "./ErrorText";
import { Link } from "./Link";

export function FormLogin() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const dispatch = useDispatch();
  const isLoading = false;
  const loginError = useSelector((state) => state.user.loginError);

  const onSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(login({ username, password }));
    },
    [dispatch, login, username, password],
  );

  return (
    <div className="shadow-proud w-96 max-w-[90%] rounded-lg bg-white">
      <div className="space-y-4 p-6">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
          Trainer Sign-In
        </h1>
        <form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
          <div>
            <Input
              required={true}
              name="email"
              type="email"
              placeholder="name@vortexgroup.co.uk"
              autoComplete="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              label="Email Address"
            />
          </div>
          <div>
            <Input
              required={true}
              name="password"
              type="password"
              autoComplete="current-password"
              placeholder="••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
            />
          </div>
          {!!loginError && <ErrorText>{loginError}</ErrorText>}
          <div className="flex items-center justify-between">
            <Link type="button" onClick={() => dispatch(setUserType(null))}>
              cancel
            </Link>
            <Button type="submit" disabled={isLoading}>
              Sign In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
