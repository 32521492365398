import React, { useState, useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { useTransition, animated, useSpring } from "react-spring";
import useMeasure from "react-use-measure";

export function CameraList({
  noInitial,
  items,
  completeItems,
  children,
  onSelect,
  currentItem,
}) {
  const [savedCompleteItems, setSavedCompleteItems] = useState(completeItems);
  const [flash, setFlash] = useState(null);
  const [containerRef, bounds] = useMeasure();

  const listToShow = noInitial ? completeItems : items;
  console.log(items, currentItem);

  useEffect(() => {
    if (completeItems.length > savedCompleteItems.length) {
      setFlash(true);
    }

    setSavedCompleteItems(completeItems);
  }, [
    completeItems,
    savedCompleteItems.length,
    setFlash,
    setSavedCompleteItems,
  ]);

  const flashAnimation = useSpring({
    opacity: flash ? 1 : 0,
    onRest: () => {
      if (flash) setFlash(false);
    },
    reset: flash,
    config: {
      duration: 200,
      easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
    },
  });

  const itemTransitions = useTransition(listToShow, {
    from: { opacity: 0, background: "rgba(0,0,0,0.8)" },
    leave: { opacity: 0 },
    update: { opacity: 1 },
    enter: noInitial
      ? [
          { background: "#047857", opacity: 1, transform: "scale(1.2)" },
          { background: "transparent", transform: "scale(1)" },
        ]
      : [{ opacity: 1 }, { background: "transparent", transform: "scale(1)" }],
  });

  return (
    <div
      className="absolute left-0 top-0 z-[90] h-full w-full"
      ref={containerRef}
    >
      <animated.div
        className="absolute z-[91] h-full w-full origin-top-left bg-emerald-500 transition-opacity"
        style={flashAnimation}
      />
      <ul className="flex flex-wrap gap-1 p-4">
        {itemTransitions((style, username) => (
          <DelegateItem
            username={username}
            style={style}
            isComplete={completeItems.includes(username)}
            isCurrent={currentItem === username}
            containerBounds={bounds}
            onSelect={onSelect}
          />
        ))}
      </ul>
      {!!children && (
        <div className="absolute bottom-0 left-0 z-[92] flex w-full flex-row justify-between bg-black/80 p-4 text-white">
          {children}
        </div>
      )}
    </div>
  );
}

const EMPTY_TRANSFORM = "translateX(0px) translateY(0px) scale(1)";

function DelegateItem({
  username,
  isComplete,
  style,
  isCurrent,
  containerBounds,
  onSelect,
}) {
  const [itemRef, bounds] = useMeasure();
  const name = username.split("_").slice(0, 2).join(" ");
  const defaultStyles =
    "rounded-sm p-2 text-sm text-gray-100 border-gray-300 border-2 transition-transform bg-black/80";
  const activeStyles = "border-emerald-600 bg-emerald-950/90";

  const className = twMerge(defaultStyles, isComplete && activeStyles);

  const selectedTransform = useMemo(() => {
    const c = containerBounds,
      i = bounds;
    if (!c || !i) return EMPTY_TRANSFORM;

    const targetX = c.width * 0.5;
    const targetY = c.height * 0.85;
    const currX = i.left + i.width / 2;
    const currY = i.top + i.height / 2;

    return `translateX(${Math.round(
      targetX - currX,
    )}px) translateY(${Math.round(targetY - currY)}px) scale(1)`;
  }, [containerBounds, bounds]);

  const placementStyle = useSpring({
    transform: isCurrent ? selectedTransform : EMPTY_TRANSFORM,
    config: {
      tension: 300,
      friction: 20,
      mass: 1,
    },
  });

  return (
    <animated.li className={className} ref={itemRef} style={placementStyle}>
      <animated.a
        className="flex cursor-pointer items-center gap-2"
        style={style}
        onClick={onSelect}
      >
        {name}
      </animated.a>
    </animated.li>
  );
}

function calculateItemTransform(cRef, iRef) {
  const c = cRef.current?.getBoundingClientRect();
  const i = iRef.current?.getBoundingClientRect();
  if (!c || !i) return "translateX(0px) translateY(0px) scale(1)";

  const targetX = c.width * 0.5;
  const targetY = c.height * 0.8;
  const currX = i.left + i.width / 2;
  const currY = i.top + i.height / 2;

  console.log(
    `translateX(${Math.round(targetX - currX)}px) translateY(${Math.round(
      targetY - currY,
    )}px) scale(1.2)`,
  );

  return `translateX(${Math.round(targetX - currX)}px) translateY(${Math.round(
    targetY - currY,
  )}px) scale(1.2)`;
}
