import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserType } from "../slices/user";
import { useSpring } from "react-spring";
import { delegateName } from "../slices/delegate";

export function SplashPage() {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.user.type);
  const canViewFrontend = useSelector((state) => state.user.canViewFrontend);
  const existingName = useSelector(delegateName);
  const viewType =
    userType === "TRAINER" && !canViewFrontend ? "LOGIN" : "HOME";

  const buttonProps = useSpring({
    transform:
      viewType === "LOGIN"
        ? "translate3d(-100%, 0, 0)"
        : "translate3d(0%, 0, 0)",
    opacity: viewType === "LOGIN" ? 0 : 1,
  });

  return (
    <div
      className="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center"
      style={buttonProps}
    >
      <button
        className="shadow-proud mt-12 rounded-lg border-2 border-blue-900 bg-white px-4 py-2 text-lg text-blue-900 transition-colors active:border-black/90 active:bg-blue-950 active:text-white"
        onClick={() => dispatch(setUserType("DELEGATE"))}
      >
        {existingName ? `Continue as ${existingName}` : "Begin your course"}
      </button>
    </div>
  );
}
