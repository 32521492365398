import React from "react";

export function MenuQR({ value, onChange, options }) {
  if (options.length <= 1) return null;
  return (
    <div className="py-2 text-right">
      <select
        onChange={(e) => onChange(parseInt(e.target.value))}
        value={value}
        className="border border-slate-300 bg-slate-200 p-2"
      >
        {options.map((o, i) => {
          return (
            <option value={i} key={i}>
              {o.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
