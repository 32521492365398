import React, { forwardRef } from "react";

function LinkComponent({ className = "", ...buttonProps }, ref) {
  return (
    <button
      ref={ref}
      type="button"
      className={`${className} border-none bg-none underline outline-none`}
      {...buttonProps}
    />
  );
}

export const Link = forwardRef(LinkComponent);
