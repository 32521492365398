import React, {
  forwardRef,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import { Label } from "./Label";
import { useController } from "react-hook-form";
import { ErrorText } from "./ErrorText";

export function DateOfBirth({ control, name, errorText }) {
  const { field } = useController({ control, name, rules: { required: true } });
  const [dd, mm, yyyy] = (field.value || "///").split("/");
  const monthsRef = useRef();
  const yearsRef = useRef();
  const [days, setDays] = useState(dd);
  const [months, setMonths] = useState(mm);
  const [years, setYears] = useState(yyyy);

  const onDayChange = useCallback(
    (e) => {
      const { value } = e.target;
      setDays(value);

      if (value.length === 2) {
        monthsRef.current?.focus();
      }
    },
    [setDays],
  );

  const onMonthChange = useCallback(
    (e) => {
      const { value } = e.target;
      setMonths(value);

      if (value.length === 2) {
        yearsRef.current?.focus();
      }
    },
    [setDays],
  );

  useEffect(() => {
    if (!days && !months && !years) return;
    field.onChange(`${days}/${months}/${years}`);
  }, [field.onChange, days, months, years]);

  return (
    <div className="flex flex-col items-start gap-1">
      <Label required>Date Of Birth </Label>
      <div
        className={`flex w-48 items-center gap-x-1 rounded-lg border border-gray-300 bg-gray-50 p-2.5 pr-2 !leading-none text-gray-900 focus-within:border-amber-600 focus:outline-amber-600 sm:text-sm ${
          errorText ? "border-red-600" : ""
        }`}
      >
        <DateOfBirthInput
          maxLength="2"
          placeholder="dd"
          value={days}
          onChange={onDayChange}
          name="day"
        />
        <span>/</span>
        <DateOfBirthInput
          maxLength="2"
          ref={monthsRef}
          placeholder="mm"
          value={months}
          onChange={onMonthChange}
          name="month"
        />
        <span>/</span>
        <DateOfBirthInput
          maxLength="4"
          placeholder="yyyy"
          ref={yearsRef}
          value={years}
          onChange={(e) => setYears(e.target.value)}
          onBlur={() => field.onBlur()}
          name="year"
        />
      </div>
      {errorText ? (
        <ErrorText className="!mt-0 !py-0 text-sm font-bold text-red-700">
          {errorText.message}
        </ErrorText>
      ) : null}
    </div>
  );
}

const DateOfBirthInput = forwardRef(({ ...additionalProps }, ref) => {
  return (
    <input
      type="text"
      pattern="\d*"
      {...additionalProps}
      className="w-1/3 bg-inherit text-center text-sm leading-none focus:outline-none"
      autoComplete="none"
      ref={ref}
    />
  );
});

DateOfBirthInput.displayName = "DateOfBirthInput";
