import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { SplashPage } from "./components/SplashPage";
import { RoutesDelegate } from "./components/RoutesDelegate";
import { RoutesTrainer } from "./components/RoutesTrainer";
import { Container } from "./components/Container";
import { TransitionGroup } from "./components/TransitionGroup";

const pages = [SplashPage, RoutesTrainer, RoutesDelegate];

export function Routes() {
  const userType = useSelector((state) => state.user.type);
  const isSplashPage = !userType;

  const pageIndex = useMemo(() => {
    return isSplashPage ? 0 : userType === "TRAINER" ? 1 : 2;
  }, [isSplashPage, userType]);

  return (
    <Container isSplashPage={isSplashPage}>
      <TransitionGroup pages={pages} pageIndex={pageIndex} />
    </Container>
  );
}
