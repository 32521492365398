import { createSlice } from "@reduxjs/toolkit";

import {
  getLiveCourses,
  postLiveCourse,
  uploadImage,
  putFeedback,
} from "../thunks/api";
import { reset } from "./shared";

const apiSlice = createSlice({
  name: "api",
  initialState: {
    courses: [],
    isLoading: false,
    isSaving: false,
    savedCourses: [],
    cachedImages: [],
    uploadedImages: [],
    lastSaved: null,
    lastUpdated: 0,
    queuedFeedback: [],
  },
  reducers: {
    imageCached(state, action) {
      state.cachedImages.push(action.payload);
    },
    completeCourse(state) {
      state.savedCourses.push(state.lastSaved);
    },
    queueFeedback(state, action) {
      state.queuedFeedback.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLiveCourses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLiveCourses.fulfilled, (state, action) => {
        state.courses = action.payload;
        state.isLoading = false;
      })
      .addCase(getLiveCourses.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postLiveCourse.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(postLiveCourse.fulfilled, (state, action) => {
        state.lastSaved = action.payload;
        state.isSaving = false;
        state.lastUpdated = new Date().getTime();
      })
      .addCase(postLiveCourse.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(putFeedback.fulfilled, (state, action) => {
        return {
          ...state,
          queuedFeedback: [...state.queuedFeedback].filter(
            (item) =>
              item.courseId !== action.payload.courseId ||
              item.delegateId !== action.payload.delegateId,
          ),
        };
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.uploadedImages.push(action.payload);
      })
      .addCase(reset, (state, action) => {
        state.isLoading = false;
        state.isSaving = false;
        state.cachedImages = [];
        state.uploadedImages = [];

        if (action.payload === true) {
          state.courses = [];
        }
      });
  },
});

export const { imageCached, completeCourse, queueFeedback } = apiSlice.actions;

export const courses = (state) =>
  state.api.courses.filter((c) => !state.api.savedCourses.includes(c?._id));

export const imagesToUpload = (state) =>
  state.api.cachedImages.filter(
    (key) => !state.api.uploadedImages.includes(key),
  );

export default apiSlice.reducer;
