import React from "react";
import { useTransition, animated } from "react-spring";

export function TransitionGroup({ pages, pageIndex }) {
  const transitions = useTransition(pageIndex, {
    initial: { opacity: 1, transform: "translate3d(0%,0,0)" },
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-100%,0,0)" },
  });

  return (
    <div className="relative h-full w-full">
      {transitions((style, i) => {
        const Page = pages[i];
        return (
          <animated.div
            className="absolute left-0 top-0 z-30 h-full w-full"
            style={style}
          >
            <Page />
          </animated.div>
        );
      })}
    </div>
  );
}
