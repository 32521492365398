import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";

import { reset } from "./shared";

const poolConfig = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolConfig);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    canViewFrontend: false,
    canViewApi: false,
    isLoading: false,
    type: null,
    loginError: null,
    accessToken: null,
    attributes: null,
    isAuthenticatingFrontend: false,
    errors: [],
  },
  reducers: {
    setUserType(state, action) {
      state.type = action.payload;
    },
    addError(state, action) {
      const timestamp = new Date().getTime();
      const inLast = timestamp - 3000;
      const str = JSON.stringify(action.payload.data);
      const exists = state.errors.find(
        (err) => err.timestamp > inLast && err.str === str,
      );

      if (!exists) {
        state.errors.push({
          text: action.payload.text,
          timestamp,
          str,
        });
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(reset, (state) => {
        state.type = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.canViewFrontend = false;
        state.canViewApi = false;
        state.type = null;
      })
      .addCase(authenticateFrontend.fulfilled, (state) => {
        state.canViewFrontend = true;
        state.isAuthenticatingFrontend = false;
      })
      .addCase(authenticateFrontend.rejected, (state) => {
        state.canViewFrontend = false;
        state.isAuthenticatingFrontend = false;
      })
      .addCase(authenticateFrontend.pending, (state) => {
        state.isAuthenticatingFrontend = true;
      })
      .addCase(authenticateAPI.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authenticateAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        state.canViewApi = true;
        state.attributes = action.payload.attributes;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(authenticateAPI.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.attributes = action.payload.attributes;
        state.accessToken = action.payload.accessToken;
        state.isLoading = false;
        state.canViewApi = true;
        state.canViewFrontend = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.loginError = action.error.message;
      });
  },
});

export const logout = createAsyncThunk("logout", async () => {
  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (!user) return reject("No user");
    user.signOut();
    localStorage.removeItem("live_access_token");
    resolve();
  });
});

export const authenticateFrontend = createAsyncThunk(
  "authenticateFrontend",
  async () => {
    return new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (!user) reject();

      const { attributes, accessToken } = user;
      resolve({ attributes, accessToken });
    });
  },
);

export const login = createAsyncThunk(
  "login",
  async ({ username: Username, password: Password }) => {
    return new Promise((resolve, reject) => {
      const authDetails = new AuthenticationDetails({ Username, Password });

      const userData = {
        Username,
        Pool: userPool,
      };

      const user = new CognitoUser(userData);
      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          const attributes = result.getIdToken().payload;
          const accessToken = result.getAccessToken().getJwtToken();
          window.localStorage.setItem("live_access_token", accessToken);
          resolve({ attributes, accessToken });
        },
        onFailure: (err) => {
          reject(err.message);
        },
      });
    });
  },
);

export const authenticateAPI = createAsyncThunk("authenticateAPI", async () => {
  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (!user) return reject("No user");
    user.getSession((err, session) => {
      if (err) return reject(err);
      if (!session) return reject("No auth");

      user.refreshSession(session.refreshToken, (err) => {
        if (err) return reject("Session Refresh Error");
        const attributes = session.getIdToken().payload;
        const accessToken = session.getAccessToken().getJwtToken();
        window.localStorage.setItem("live_access_token", accessToken);
        resolve({ attributes, accessToken });
      });
    });
  });
});

export const { setUserType, addError } = userSlice.actions;

export default userSlice.reducer;
