import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";

import {
  completeTransition,
  currentStage,
  shouldShowTransition,
} from "../slices/delegate";
import Vortex from "../Images/vortex-white.png";

export function PageStageTransition() {
  const transition = useSelector(shouldShowTransition);
  const next = useSelector(currentStage);
  const dispatch = useDispatch();
  const Component = transition === "FINAL" ? HoldDownVortex : DelayView;
  console.log(transition);

  return (
    <div className="relative flex h-full w-full items-center justify-center">
      {!!transition && (
        <Component
          onComplete={() => dispatch(completeTransition(transition))}
          nextStage={next}
        />
      )}
    </div>
  );
}

function DelayView({ onComplete, nextStage }) {
  const timeoutRef = useRef();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsReady(true);
    }, 60000);
  }, [setIsReady]);

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <div
        className="animate-float group h-32 w-32 touch-none border-none bg-none bg-cover outline-none [-webkit-touch-callout:none] active:animate-none group-active:drop-shadow-none"
        style={{
          backgroundImage: `url(${Vortex})`,
        }}
      />
      {isReady ? (
        <button
          className="shadow-proud rounded-lg border-2 border-blue-900 bg-white px-4 py-2 text-lg text-blue-900 transition-colors active:border-black/90 active:bg-blue-950 active:text-white"
          onClick={onComplete}
        >
          {nextStage ? `Go to ${nextStage.Name}` : "Next"}
        </button>
      ) : (
        <p className="text-center text-lg text-white/90">
          Please check back soon. <br />
          Next up: {nextStage && <strong>{nextStage.Name}</strong>}
        </p>
      )}
    </div>
  );
}

function HoldDownVortex({ onComplete }) {
  const [isPressing, setIsPressing] = useState(false);

  const bind = useDrag(({ down }) => {
    setIsPressing(down);
  });

  const onHold = useCallback(() => {
    setTimeout(onComplete, 1000);
  }, [onComplete]);

  useEffect(() => {
    if (!isPressing) return;

    const timeout = setTimeout(() => {
      setIsPressing(false);
      onHold();
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isPressing, setIsPressing, onHold]);

  const spinning = useSpring({
    to: { rotateZ: isPressing ? 360 : 0 },
    from: { rotateZ: 0 },
    reset: isPressing,
    reverse: !isPressing,
    config: isPressing
      ? { tension: 300, friction: 100 }
      : { tension: 150, friction: 30 },
    // onRest: isPressing ? undefined : onComplete, // Call onComplete when animation rests and isPressing is false
  });

  return (
    <div className="flex-col items-center gap-2 overflow-visible">
      <div className="relative flex items-center justify-center overflow-visible p-16 pb-2">
        <div
          className="absolute -z-10 h-full w-full overflow-visible bg-[radial-gradient(circle,rgba(0,0,0,0.73)0%,rgba(0,0,0,0)70%,rgba(0,0,0,0)100%)] transition-all"
          style={isPressing ? {} : { background: "transparent" }}
        ></div>
        <animated.button
          {...bind()}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
          className="animate-float group h-32 w-32 touch-none border-none bg-none bg-cover outline-none [-webkit-touch-callout:none] active:animate-none group-active:drop-shadow-none"
          style={{
            ...spinning,
            backgroundImage: `url(${Vortex})`,
          }}
        />
      </div>
      <p className="mx-auto max-w-[90%] text-center text-xl text-white/90">
        Your course is now complete. Thank you for using Vortal today!
      </p>
    </div>
  );
}
