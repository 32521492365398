import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { QRReader } from "./QRReader";
import { Button } from "./Button";
import {
  addDelegateData,
  nDelegates,
  confirmStageCompletion,
  currentStageData,
} from "../slices/trainer";
import { CameraList } from "./CameraList";

export function PageScanRegister(props) {
  console.log(props);
  const dispatch = useDispatch();

  const addDelegate = (delegate) => dispatch(addDelegateData(delegate));
  const n = useSelector(nDelegates);
  const data = useSelector(currentStageData);

  const completed = useMemo(() => {
    return Object.keys(data);
  }, [data]);

  const validate = useCallback((data) => {
    return typeof data?.userId === "string" && data?.data?.AgreedTerms === true;
  }, []);

  return (
    <QRReader onComplete={addDelegate} onClose={() => {}} validateQR={validate}>
      <CameraList noInitial={true} items={[]} completeItems={completed}>
        <div className="flex w-full items-center justify-between">
          <strong className="text-sm font-bold leading-relaxed">
            Scan delegates.
          </strong>
          {n > 0 && (
            <span>
              <Button
                onClick={() => dispatch(confirmStageCompletion())}
                variant="rinconspic"
                className="!px-2 !py-1"
              >
                Start course with {n} delegate
                {n !== 1 ? "s" : ""}
              </Button>{" "}
            </span>
          )}
        </div>
      </CameraList>
    </QRReader>
  );
}
