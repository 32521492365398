import React from "react";
import { Button } from "./Button";
import { createPortal } from "react-dom";

export function VideoError({ error }) {
  return createPortal(
    <div className="absolute left-0 top-0 z-[999999] flex h-full w-full flex-col items-center justify-center gap-6 text-white">
      <h2 className="text-center text-xl">
        <strong className="font-bold">
          Oops!
          <br />
        </strong>{" "}
        Camera failed to load.
      </h2>
      <Button variant="action" onClick={() => window.location.reload(true)}>
        Try again
      </Button>
      <pre className="w-full whitespace-pre-wrap p-2 text-sm">{error}</pre>
    </div>,
    document.body,
  );
}
