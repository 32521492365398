import React, { useEffect, useState } from "react";

export function CacheImage({ imageKey, ...imageProps }) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      if (!imageKey) return;
      const cache = await caches.open("imageCache");
      const response = await cache.match(imageKey);
      if (response) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
      } else {
        console.log(imageKey);
        const [type, key] = imageKey.split("___");
        const prefix =
          type === "PAPER" ? "delegates/documents" : "delegates/original";

        setImageUrl(`https://media.vortal.live/${prefix}/${key}`);
      }
    };

    fetchImage();
  }, [imageKey]);

  return imageUrl ? <img src={imageUrl} {...imageProps} /> : null;
}
