import { reset } from "../slices/shared";
import { postLiveCourse, putFeedback, uploadImage } from "../thunks/api";

export const syncMiddleware = (store) => {
  let timeout = null;
  if (!timeout) timeout = setTimeout(sync, 0);

  return (next) => (action) => {
    next(action);

    if (action.type === reset().type) {
      clearTimeout(timeout);
    } else if (!timeout) {
      timeout = setTimeout(sync, 0);
    }
  };

  async function sync() {
    console.log("tryna sync");
    const state = store.getState();
    const setNext = () => (timeout = setTimeout(sync, 10000));
    saveFeedbacks(store);
    if (state.user.type !== "TRAINER") return setNext();
    saveCourse(store);
    uploadImages(store);
    return setNext();
  }
};

function saveFeedbacks(store) {
  console.log("save de feedbacks");
  const queuedFeedback = store.getState()?.api.queuedFeedback || [];
  console.log(queuedFeedback);
  if (!queuedFeedback.length) return;

  queuedFeedback.forEach((item) => {
    return store.dispatch(putFeedback(item));
  });
}

function saveCourse(store) {
  const state = store.getState();
  const toUpload = getUploadData(state);
  if (toUpload && !state.api.savedCourses.includes(toUpload.courseId)) {
    return store.dispatch(postLiveCourse(toUpload));
  }
}

async function uploadImages(store) {
  const cache = await caches.open("imageCache");
  const items = await cache.keys();
  const toAdd = items.map((item) => item.url.split("/")[3]).filter(Boolean);
  toAdd.forEach((key) => {
    triggerUpload(store, key);
  }, []);
}

function triggerUpload(store, key) {
  store.dispatch(uploadImage(key));
}

function getUploadData(state) {
  const data = state.trainer.data;
  const stages = state.trainer.stages;
  const course = state.trainer.course;
  const delegates = state.trainer.delegates;

  if (!data || !stages || !course || !delegates) return null;

  return {
    courseId: course._id,
    data: data.map((r, i) => {
      return {
        data: r,
        stageType: stages[i].Type,
      };
    }),
  };
}
